import * as React from 'react'
import useProductsList from 'hooks/useProductList'
import { auth } from 'config/firebase'
import { InstantSearch, Configure } from 'react-instantsearch'
import { Box, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material'
import SearchBox from 'components/SearchBox'
import LaboratoryFilter from 'components/ChipFilters/LaboratoryFilter'
import PharmaceuticalForm from 'components/ChipFilters/PharmaceuticalForm'
import StockFilter from 'components/ChipFilters/StockFilter'
import AllProductsTable from 'features/products/components/AllProductsTable'
import { Toaster } from 'sonner'
import FlexContainer from 'components/FlexContainer'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ReportProblemIcon from '@mui/icons-material/ReportProblemOutlined'
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined'
import ActiveIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { Add as AddIcon } from '@mui/icons-material'
import RemoveFilters from 'features/products/components/RemoveFilters'
import { useNavigate } from 'react-router-dom'
import { RECEIVE_ORDER } from 'config/routes/paths'
import ButtonWithShortcut from 'components/ButtonWithShortcut'
import { addMonths, getTime } from 'date-fns'
import { Helmet } from 'react-helmet'
import RotationFilter from 'components/ChipFilters/RotationFilter'

const NEXT_4_MONTHS = getTime(addMonths(new Date(), 4))

export default function AllProducts() {
  const { client, REACT_APP_ALGOLIA_INDEX, pharmacy_ID } = useProductsList()

  const [closeOnSelect, setCloseOnSelect] = React.useState(true)
  const [showFilters, setShowFilters] = React.useState(true)
  const searchbarRef = React.useRef(null)
  const theme = useTheme()
  const navigate = useNavigate()

  const [selectedTab, setSelectedTab] = React.useState('ACTIVE')
  const [hitsPerPage, setHitsPerPage] = React.useState(15)
  const [sortModel, setSortModel] = React.useState([])

  const TabOptions = {
    ALL: '',
    ACTIVE: `AND archived=0`,
    ARCHIVIED: `AND archived=1`,
    BEST_SELLERS: `AND sold_units >= 1`,
    LOW_SELLERS: `AND sold_units <= 1`,
    EXPIRES: `AND expiration_date <= ${NEXT_4_MONTHS} AND stock > 0`,
  }

  const tabFilter = TabOptions[selectedTab]

  const user = auth?.currentUser

  const addProduct = () => {
    navigate(RECEIVE_ORDER)
  }

  React.useEffect(() => {
    if (selectedTab === 'EXPIRES') {
      setHitsPerPage(50)
      setSortModel([{ field: 'expiration_date_timestamp', sort: 'asc' }])
    } else if (selectedTab === 'ACTIVE') {
      setHitsPerPage(15)
      setSortModel([{ field: 'sold_units', sort: 'desc' }])
    } else if (selectedTab === 'ARCHIVIED') {
      setHitsPerPage(15)
      setSortModel([{ field: 'stock_raw', sort: 'desc' }])
    } else if (selectedTab === 'BEST_SELLERS') {
      setHitsPerPage(15)
      setSortModel([{ field: 'sold_units', sort: 'desc' }])
    } else if (selectedTab === 'LOW_SELLERS') {
      setHitsPerPage(100)
      setSortModel([{ field: 'stock_raw', sort: 'desc' }])
    }
  }, [selectedTab])

  return (
    <Box sx={{ width: '100%' }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Productos</title>
      </Helmet>
      <FlexContainer alignItems="flex-start">
        <Typography
          variant="h5"
          sx={{
            fontWeight: '700',
            mb: 0,
            pb: 0,
            lineHeight: 1,
          }}
        >
          Productos
        </Typography>

        <Box sx={{ width: '280px' }}>
          <ButtonWithShortcut
            startIcon={<AddIcon />}
            onClick={addProduct}
            type="submit"
            keyCode={65}
            keyLabel="A"
            size="small"
            uppercase={false}
          >
            Agregar producto
          </ButtonWithShortcut>
        </Box>
      </FlexContainer>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedTab}
          onChange={(_, value) => {
            setSelectedTab(value)
          }}
          sx={{
            '& .MuiTab-root': {
              margin: 0,
              minHeight: '28px !important',
              textTransform: 'none !important',
            },
          }}
        >
          <Tab size="small" iconPosition="start" label="Todos" value="ALL" />
          <Tab
            size="small"
            iconPosition="start"
            icon={<ActiveIcon fontSize="small" />}
            label="Activos"
            value="ACTIVE"
          />
          <Tab
            iconPosition="start"
            icon={<ArchiveIcon fontSize="small" />}
            label="Archivados"
            value="ARCHIVIED"
          />
          <Tab
            size="small"
            iconPosition="start"
            icon={<AttachMoneyIcon fontSize="small" />}
            label="Los mas vendidos"
            value="BEST_SELLERS"
          />
          <Tab
            size="small"
            iconPosition="start"
            icon={<TrendingDownIcon fontSize="small" />}
            label="Con poca rotación"
            value="LOW_SELLERS"
          />
          <Tab
            size="small"
            iconPosition="start"
            icon={<ReportProblemIcon fontSize="small" />}
            label="Prontos a expirar"
            value="EXPIRES"
          />
        </Tabs>
      </Box>
      <Box sx={{ pt: 1 }}>
        <InstantSearch
          indexName={REACT_APP_ALGOLIA_INDEX}
          searchClient={client}
          routing={true}
          insights={true}
        >
          <Configure
            analytics={true}
            filters={`pharmacy_ID:${pharmacy_ID} ${tabFilter}`}
            // filters={`archived=0`}
            userToken={user?.uid}
            hitsPerPage={hitsPerPage}
            // ranking={{
            //   customRanking: ['desc(price)'],
            // }}
          />
          <FlexContainer justifyContent="flex-start">
            <Box
              sx={{
                mr: 1,
                padding: 0,
                margin: 0,
                backgroundColor: theme.palette.action.hover,
                borderRadius: 1,
              }}
            >
              <Box sx={{ width: '500px' }}>
                <SearchBox
                  padding={'9px'}
                  size="medium"
                  showMoreButton={false}
                  variant="filled"
                  placeholder="Buscar productos"
                  initialKeyword={''}
                  {...{
                    closeOnSelect,
                    setCloseOnSelect,
                    showFilters,
                    setShowFilters,
                    searchbarRef,
                  }}
                />
              </Box>
            </Box>
            {showFilters ? (
              <Stack direction="row" spacing={1} sx={{ ml: 1, mr: 1 }}>
                <LaboratoryFilter size="large" borderRadius={'12px'} />
                <PharmaceuticalForm size="large" borderRadius={'12px'} />
                <StockFilter size="large" hideSearchbar borderRadius={'12px'} />
                <RotationFilter
                  size="large"
                  hideSearchbar
                  borderRadius={'12px'}
                />

                <RemoveFilters />
              </Stack>
            ) : null}
          </FlexContainer>
          <AllProductsTable
            hitsPerPage={hitsPerPage}
            setHitsPerPage={setHitsPerPage}
            sortModel={sortModel}
            searchbarRef={searchbarRef}
            showArchived={selectedTab === 'ARCHIVIED'}
          />
        </InstantSearch>
      </Box>
      <Toaster richColors position="top-right" />
    </Box>
  )
}
