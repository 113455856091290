import usePharmacy from 'hooks/usePharmacy'
import { isEmpty } from 'lodash'
import { useLocations } from 'stores/useLocations'
import { getLocationsByPharmacy } from 'services/locations/getAllLocations'

const listLocations = [
  'A1',
  'A2',
  'A3',
  'B1',
  'B2',
  'B3',
  'B4',
  'B5',
  'C1',
  'C2',
  'C3',
  'C4',
  'C5',
  'D1',
  'D2',
  'D3',
  'D4',
  'D5',
  'E1',
  'E2',
  'E3',
  'E4',
  'E5',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'G1',
  'G2',
  'G3',
  'G4',
  'G5',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'I1',
  'I2',
  'I3',
  'I4',
  'I5',
  'J1',
  'J2',
  'J3',
  'J4',
  'J5',
  'K1',
  'K2',
  'K3',
  'K4',
  'K5',
  'L1',
  'L2',
  'L3',
  'L4',
  'L5',
  'MT1',
  'MT2',
  'MT3',
  'MT4',
  'MT5',
  'MT6',
  'MT7',
  'VG1',
  'VG2',
  'VG3',
  'VG4',
  'VG5',
  'VG6',
  'VP1',
  'VP2',
  'VP3',
  'VP4',
  'VP5',
  'VP6',
]

export default function useLocationsSelector() {
  const { selectedPharmacy } = usePharmacy()
  const locations = useLocations(state => state.locations)
  const setLocations = useLocations(state => state.setLocations)
  const getLocationByName = useLocations(state => state.getLocationByName)

  // const addLocations = async location => {
  //   try {
  //     listLocations.forEach(async location => {
  //       const response = await addDoc(collection(db, 'locations'), {
  //         location,
  //         pharmacy_ID: selectedPharmacy?.pharmacy_ID,
  //       })
  //     })

  //     console.log('response', response)

  //     return response
  //   } catch (error) {
  //     console.error('Error al agregar la ubicación')
  //     console.error(error)
  //   }
  // }

  const updateLocationList = async () => {
    if (isEmpty(selectedPharmacy)) return

    const _locations = await getLocationsByPharmacy(
      selectedPharmacy?.pharmacy_ID
    )

    setLocations(_locations)
  }

  return {
    locationsObj: locations,
    locations: Object.values(locations),
    updateLocationList,
    getLocationByName,
  }
}
