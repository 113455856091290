import React from 'react'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'

import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { isEmpty } from 'lodash'

export default function ProductMenu({
  anchorEl,
  open,
  menu = null,
  handleClose,
  data,
  productDetail,
  editProductModal,
  archiveProduct = () => {},
  unarchiveProduct = () => {},
}) {
  const options = [
    {
      label: 'Ver detalle',
      value: 'SEE_DETAIL',
      icon: OpenInNewOutlinedIcon,
      action: () => {
        productDetail.open({ data: { product_ID: data?.product_code } })
      },
    },
    {
      label: 'Editar producto',
      value: 'EDIT_PRODUCT',
      icon: EditOutlinedIcon,
      action: () => {
        editProductModal.open({ data: { product: data } })
      },
    },
    // {
    //   label: 'Ver historial',
    //   value: 'HISTORY',
    //   icon: HistoryOutlinedIcon,
    //   action: () => {
    //     console.log({ data, action: 'HISTORY' })
    //   },
    // },

    // {
    //   divider: true,
    //   label: 'Agregar a la lista de compras',
    //   value: 'ADD_TO_CART',
    //   icon: AddShoppingCartOutlinedIcon,
    //   action: () => {
    //     console.log({ data, action: 'ADD_TO_CART' })
    //   },
    // },
    data.archived
      ? {
          color: 'success',
          label: 'Desarchivar',
          value: 'UNARCHIVE_PRODUCT',
          icon: UnarchiveOutlinedIcon,
          action: () => {
            // console.log({ data, action: 'ARCHIVE_PRODUCT' })
            unarchiveProduct(data)
          },
        }
      : {
          color: 'error',
          label: 'Archivar',
          value: 'ARCHIVE_PRODUCT',
          icon: ArchiveOutlinedIcon,
          action: () => {
            // console.log({ data, action: 'ARCHIVE_PRODUCT' })
            archiveProduct(data)
          },
        },
  ]

  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        isEmpty(menu) ? undefined : { top: menu?.mouseY, left: menu?.mouseX }
      }
    >
      {options?.map(option => {
        const Icon = option.icon

        return (
          <MenuItem
            divider={Boolean(option?.divider)}
            key={option.value}
            onClick={() => {
              option?.action()
              handleClose()
            }}
            color={option?.color ? option?.color : 'text.primary'}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={option?.label} />
          </MenuItem>
        )
      })}
    </Menu>
  )
}
