import useParsers from 'hooks/useParsers'
import usePharmacy from 'hooks/usePharmacy'
import { useEffect, useState } from 'react'
import { getAverageSalesPerDay } from 'services/inventory/getAverageSalesPerDay'
import { searchProductInDB } from 'services/searchProductInDB'
import { isNumber, isDate } from 'lodash'
import { getUserById } from 'utils/getUserById'
import { getAverageSalesPerWeek } from 'services/inventory/getAverageSalesPerWeek'
import { getStockStatus } from 'services/inventory/getStockStatus'
import { getLabel, Status } from 'components/ChipFilters/StockFilter'

export default function useProductDetail({ product_ID }) {
  const [state, setState] = useState({ error: false, loading: true, data: {} })
  const { parseAmount } = useParsers()
  const { selectedPharmacy, users } = usePharmacy()

  const getProductData = async payload => {
    try {
      const labAndForm = `${payload?.pharmaceutical_form} ${payload?.laboratory}`

      const title = `${payload?.product_name} ${labAndForm}`

      const averageSalesPerWeek = await getAverageSalesPerWeek(payload)
      const { averageSalesPerDay, totalSalesLabel, totalSalesCount } =
        await getAverageSalesPerDay(payload)

      const minStock = payload?.manual_min_stock
      const maxStock = payload?.manual_max_stock

      const isTimestamp = isNumber(payload?.last_sale)
      const isValidDate = isDate(payload?.last_sale)

      const lastSale = isTimestamp || isValidDate ? payload?.last_sale : '-'

      // const formattedDate = !Boolean(lastSale)
      //   ? ''
      //   : isTimestamp || isValidDate
      //   ? formatDate(payload?.lastSale, 'eee dd MMMM yyyy h:mm a')
      //   : 'Sin ventas hasta la fecha'

      const lastSalePretty = ``

      const result = Math.ceil(payload?.stock / averageSalesPerDay)

      const createdBy = getUserById({ users, user_ID: payload?.created_by })

      const low_stock = payload?.stock < payload?.manual_min_stock
      const excessive_stock = payload?.stock > payload?.manual_max_stock

      const stockStatusKey = getStockStatus({
        stock: payload?.stock,
        low_stock,
        excessive_stock,
      })

      const option = Status[stockStatusKey]

      const stockStatus = getLabel(option)

      return {
        ...payload,
        title,
        averageSalesPerWeek,
        minStock,
        maxStock,
        lastSale,
        result,
        createdBy,
        lastSalePretty,
        averageSalesPerDay,
        totalSalesLabel,
        totalSalesCount,
        stockStatus,
      }
    } catch (err) {
      console.error(err)
      console.error('getProductData')
    }
  }

  const getData = async product_ID => {
    try {
      const documentSnapshot = await searchProductInDB({
        code: product_ID,
        selectedPharmacy,
      })

      if (documentSnapshot.exists) {
        const product = {
          product_ID: documentSnapshot.id,
          ...documentSnapshot.data(),
        }

        const productData = await getProductData(product)

        setState({
          error: false,
          data: productData,
          loading: false,
        })
      } else {
        setState({
          error: false,
          data: {},
          loading: false,
        })
      }
    } catch (error) {
      console.error('Error al obtener el detalle del producto')
      console.error(error)
    }
  }

  useEffect(() => {
    if (!product_ID) return

    getData(product_ID)
  }, [product_ID])

  return {
    state,
    parseAmount,
  }
}
