import { Box, IconButton, useTheme } from '@mui/material'
import React, { useState } from 'react'
import MoreIcon from '@mui/icons-material/MoreHorizOutlined'

import ProductDetail from '../ProductDetail'
import useModal from 'hooks/useModal'
import ProductMenu from '../ProductMenu'

export default function ProductOptions({
  data,
  editProductModal,
  archiveProduct,
  unarchiveProduct,
}) {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const productDetail = useModal()
  const [menu, setMenu] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    setMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    })
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <IconButton
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
        }}
        onClick={handleClick}
      >
        <MoreIcon />
      </IconButton>

      <ProductMenu
        anchorEl={anchorEl}
        open={open}
        menu={menu}
        handleClose={handleClose}
        data={data}
        productDetail={productDetail}
        editProductModal={editProductModal}
        archiveProduct={archiveProduct}
        unarchiveProduct={unarchiveProduct}
      />

      {productDetail.visible ? (
        <ProductDetail
          product_ID={productDetail?.data?.product_ID}
          onClose={productDetail.close}
        />
      ) : null}
    </Box>
  )
}
