import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const usePrinters = create(
  persist(
    set => ({
      selectedPrinter: null,
      printers: [],
      defaultPrinter: null,
      setPrinters: printers => set({ printers }),
      setSelectedPrinter: printerId => set({ selectedPrinter: printerId }),
      setDefaultPrinter: printer => set({ defaultPrinter: printer }),
    }),
    {
      name: 'printers',
    }
  )
)
