import { useEffect } from 'react'
import { getPrinters } from 'services/printers/getPrinters'
import {
  Box,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined'
import FlexContainer from 'components/FlexContainer'
import { usePrinters } from 'stores/usePrinters'
import { isEmpty } from 'lodash'

const uniquePrinterId = 74072686

export default function SelectPrinter() {
  //Estaods para almacenar las impresoras
  const selectedPrinter = usePrinters(state => state.selectedPrinter)
  const printers = usePrinters(state => state.printers)
  const setPrinters = usePrinters(state => state.setPrinters)
  const setSelectedPrinter = usePrinters(state => state.setSelectedPrinter)
  const setDefaultPrinter = usePrinters(state => state.setDefaultPrinter)

  useEffect(() => {
    if (!isEmpty(printers)) return

    const fetchPrinters = async () => {
      const printerList = await getPrinters()

      if (!isEmpty(printerList)) {
        setPrinters(printerList)

        const defaultPrinter = printerList.find(
          printer => printer.id === uniquePrinterId
        )

        setDefaultPrinter(defaultPrinter)
        setSelectedPrinter(uniquePrinterId)
      } else {
        setPrinters([])
        setSelectedPrinter(null)
        setDefaultPrinter(null)
      }
    }

    fetchPrinters()
  }, [printers])

  return (
    <Box sx={{ mr: 3 }}>
      <FlexContainer>
        <Typography variant="body1" sx={{ mr: 2 }}>
          Impresora:
        </Typography>
        <Select
          fullWidth
          variant="filled"
          size={'small'}
          value={selectedPrinter}
          onChange={e => setSelectedPrinter(e.target.value)}
          label="Impresora"
          startAdornment={
            <InputAdornment position="start">
              <LocalPrintshopOutlinedIcon />
            </InputAdornment>
          }
          sx={theme => ({
            '& .MuiInputBase-input': {
              borderRadius: theme.shape.borderRadius,
              border: 'none',
              outline: 'none',
              padding: theme.spacing(1, 1, 1, 0),
              transition: theme.transitions.create('width'),
              width: '100%',
              [theme.breakpoints.up('md')]: {
                width: '20ch',
              },
            },
          })}
        >
          {printers?.map(printer => (
            <MenuItem key={printer.id} value={printer.id}>
              {printer.name}
            </MenuItem>
          ))}
        </Select>
      </FlexContainer>
    </Box>
  )
}
