import React from 'react'
import { Box, Button, Pagination, Typography } from '@mui/material'
import useAllProductsTable from './useAllProductsTable'
import FlexContainer from 'components/FlexContainer'
import useModal from 'hooks/useModal'
import ProductDetail from '../ProductDetail'
import EditProductModal from '../EditProductModal'
import Table from './Table'

export default function AllProductsTable({
  hitsPerPage,
  setHitsPerPage,
  sortModel,
  searchbarRef,
  showArchived = false,
}) {
  const {
    handleChangePage,
    columns,
    currentRefinement,
    nbHits,
    nbPages,
    rows,
    refreshTheProductOnTheTable,
    editProductModal,
    productDetail,
    archiveProduct,
    unarchiveProduct,
    updateProducts,
  } = useAllProductsTable()

  return (
    <Box>
      {/* <Button color="error" variant="contained" onClick={updateProducts}>
        Actualizar invenario
      </Button> */}
      <FlexContainer mt={2} mb={0}>
        <Box>
          <Typography variant="body2">{`Resultados: ${nbHits} Productos`}</Typography>
        </Box>

        <Pagination
          count={nbPages}
          shape="rounded"
          page={currentRefinement + 1}
          onChange={(_, payload) => {
            const page = payload - 1

            handleChangePage(page)
          }}
        />
      </FlexContainer>
      <Table
        {...{
          editProductModal,
          rows,
          columns,
          setHitsPerPage,
          handleChangePage,
          currentRefinement,
          nbHits,
          nbPages,
          hitsPerPage,
          sortModel,
          showArchived,
          editProductModal,
          productDetail,
          archiveProduct,
          unarchiveProduct,
        }}
      />

      {productDetail.visible ? (
        <ProductDetail
          product_ID={productDetail?.data?.product_ID}
          onClose={productDetail.close}
        />
      ) : null}
      {editProductModal.visible ? (
        <EditProductModal
          product={editProductModal?.data?.product}
          onClose={() => {
            editProductModal.close()
            searchbarRef?.current?.focus()
          }}
          onUpdate={updatedProduct => {
            refreshTheProductOnTheTable(updatedProduct)
          }}
        />
      ) : null}
    </Box>
  )
}
