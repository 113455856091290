import React, { useState } from 'react'
import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material'
import { getShortcutPrefix } from 'utils/getShortcutPrefix'
import { useKeyboardEvents } from 'hooks/useKeyboardEvents'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { isFunction, uniqueId } from 'lodash'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import SearchIcon from '@mui/icons-material/Search'
import useModal from 'hooks/useModal'
import SearchProductModal from 'components/SearchProductModal'
import { createPortal } from 'react-dom'

const EnterKey = 13
const TabKey = 9

export function ScanInput({
  action,
  onSelect,
  inputRef = null,
  placeholder = 'Escanear producto para agregarlo a la factura (Ctrl+E)',
  tabIndex = 100,
}) {
  const [value, setValue] = useState('')
  const modal = useModal()

  const resetValue = () => {
    setValue('')
  }

  const focus = () => {
    inputRef?.current?.focus()
    resetValue()
  }

  useKeyboardEvents({
    keyCode: 75,
    action: modal.open,
    ctrlKey: true,
    shiftKey: false,
    altKey: false,
  })

  useKeyboardEvents({
    keyCode: 69,
    action: focus,
    ctrlKey: true,
    shiftKey: false,
    altKey: false,
  })

  //Focus on the input when the Enter key is pressed
  useKeyboardEvents({
    keyCode: EnterKey,
    action: () => {
      inputRef?.current?.focus()
    },
    ctrlKey: false,
    shiftKey: false,
    altKey: false,
  })

  const ctrlLabel = getShortcutPrefix()

  return (
    <Box>
      <TextField
        variant="filled"
        inputRef={inputRef}
        size="medium"
        hiddenLabel
        label=""
        fullWidth
        inputProps={{ tabIndex: tabIndex }}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="medium" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={`Buscar producto (${ctrlLabel}+K)`}>
                <IconButton onClick={modal.open} tabIndex={100}>
                  <KeyboardArrowDownIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        onKeyDown={event => {
          if (event?.keyCode === EnterKey || event?.keyCode === TabKey) {
            const productCode = event?.target?.value

            action({
              productCode,
              onFailure: () => {
                modal.open({ data: { initialKeyword: productCode } })
              },
            })
            resetValue()
          }
        }}
        value={value}
        onChange={event => {
          const _value = event.target?.value

          setValue(_value)
        }}
      />

      {modal.visible
        ? createPortal(
            <SearchProductModal
              initialKeyword={modal?.data?.initialKeyword || ''}
              onClose={modal.close}
              onSelect={({ product, onSuccess }) => {
                onSelect(product)

                if (isFunction(onSuccess)) {
                  onSuccess()
                }
              }}
            />,
            document.body
          )
        : null}
    </Box>
  )
}
