import useParsers from 'hooks/useParsers'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'
import useLocationsSelector from 'hooks/selectors/useLocationsSelector'
import { writeBatch } from 'firebase/firestore'
import { db } from 'config/firebase'
import { getStockStatus } from 'services/inventory/getStockStatus'
import { numberIsValid } from 'utils/numbers/numberIsValid'
import { getProductRef } from 'services/getProductRef'
import { toast } from 'sonner'

const INITIAL_VALUE = {
  product_code: '',
  product_name: '',
  // laboratory: '',
  // pharmaceutical_form: '',
  manual_min_stock: '',
  manual_max_stock: '',
  location: '',
  archive_when_exhausted: false,
  notify_when_sold: false,
}

export default function useEditProduct({ product, onClose, onUpdate }) {
  const { parseAmount } = useParsers()
  const [seeMore, setSeeMore] = useState(false)
  const [archiveWhenExhausted, setArchiveWhenExhausted] = useState(false)
  const [notifyWhenSold, setNotifyWhenSold] = useState(false)

  const { getLocationByName } = useLocationsSelector()

  const toggleSeeMore = event => {
    setSeeMore(event.target.checked)
  }

  const toggleArchiveWhenExhausted = event => {
    setArchiveWhenExhausted(event.target.checked)
  }

  const toggleNotifyWhenSold = event => {
    setNotifyWhenSold(event.target.checked)
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: INITIAL_VALUE,
  })

  const getFormProps = ({ name, rules = { required: true } }) => {
    return {
      name,
      control,
      errors,
      rules,
    }
  }

  const resetForm = () => {
    reset(INITIAL_VALUE)
    setSeeMore(false)
    setArchiveWhenExhausted(false)
    setNotifyWhenSold(false)
  }

  const onSubmit = async data => {
    if (isEmpty(data?.location)) {
      toast.error('La ubicación es requerida')

      return
    }

    const batchInstance = writeBatch(db)

    const productRef = getProductRef(product?.objectID)

    const stock = product?.stock

    const manual_min_stock = numberIsValid(Number(data?.manual_min_stock))
      ? Number(data?.manual_min_stock)
      : 0

    const manual_max_stock = numberIsValid(Number(data?.manual_max_stock))
      ? Number(data?.manual_max_stock)
      : 0

    const low_stock = stock < manual_min_stock
    const excessive_stock = stock > manual_max_stock

    const stock_status = getStockStatus({
      stock,
      low_stock,
      excessive_stock,
    })

    const productCode = data?.product_code || product?.product_code
    const productName = data?.product_name || product?.product_name
    // const pharmaceuticalForm = data.pharmaceutical_form || product?.pharmaceutical_form
    // const laboratory = data.laboratory || product?.laboratory
    const location = data?.location?.value || product?.location

    const productEddited = {
      product_code: productCode,
      product_name: productName,
      // pharmaceutical_form: pharmaceuticalForm,
      // laboratory,
      location: location || '',
      low_stock,
      excessive_stock,
      stock_status,
      manual_min_stock,
      manual_max_stock,
      notify_when_sold: notifyWhenSold,
      archive_when_exhausted: archiveWhenExhausted,
    }

    batchInstance.update(productRef, productEddited)

    await batchInstance.commit()

    resetForm()

    onUpdate({
      ...product,
      product_name_raw: productEddited?.product_name,
      stock_raw: product?.stock,
      min_stock: manual_min_stock,
      max_stock: manual_max_stock,
      expiration_date_timestamp: product?.expiration_date,
      current_price_number: product?.current_price,

      ...productEddited,
    })

    toast.success('Producto editado exitosamente')

    onClose()
  }

  useEffect(() => {
    if (isEmpty(product)) return

    const location = getLocationByName(product?.location)

    setValue('product_name', product?.product_name)
    setValue('product_code', product?.product_code)
    setValue('pharmaceutical_form', product?.pharmaceutical_form)
    setValue('laboratory', product?.laboratory)
    setValue('location', location)

    setValue('manual_min_stock', product?.manual_min_stock)
    setValue('manual_max_stock', product?.manual_max_stock)

    setArchiveWhenExhausted(Boolean(product?.archive_when_exhausted))
    setNotifyWhenSold(Boolean(product?.notify_when_sold))
  }, [product])

  return {
    parseAmount,
    getFormProps,
    errors,
    watch,
    setValue,
    seeMore,
    toggleSeeMore,
    onSubmit,
    handleSubmit,
    archiveWhenExhausted,
    notifyWhenSold,
    toggleArchiveWhenExhausted,
    toggleNotifyWhenSold,
  }
}
