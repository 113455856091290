import { useEffect, useMemo, useRef, useState } from 'react'
import useModal from 'hooks/useModal'
import { isEmpty } from 'lodash'
import usePharmacy from 'hooks/usePharmacy'
import { sumBy } from 'lodash'
import { getUnitLabel } from 'utils/getUnitLabel'
import { getDescription } from 'utils/getDescription'
import { searchProductInDB } from 'services/searchProductInDB'
import { getUserById } from 'utils/getUserById'
import useParsers from 'hooks/useParsers'
import { printReceipt as printReceiptAction } from 'services/printers/printReceipt'
import { getAffectedProducts } from 'utils/getAffectedProducts'
import { auth, db } from 'config/firebase'
import useLocalStorage from 'hooks/useLocalStorage'
import { toast } from 'sonner'
import { addDoc, collection } from 'firebase/firestore'
import { getReceiptTemplate } from 'services/printers/getTemplate'
import { usePrinters } from 'stores/usePrinters'
import { format } from 'date-fns'
import { HOME } from 'config/routes/paths'
import { useNavigate } from 'react-router-dom'
import { getStockStatus } from 'services/inventory/getStockStatus'
import Swal from 'sweetalert2'
import './styles.css'

export default function useCreateInvoice({ ID }) {
  const { selectedPharmacy, users } = usePharmacy()
  const { pharmacy_ID } = selectedPharmacy
  const invoiceProductsID = `invoice_products_${pharmacy_ID}_${ID}`

  const [products, setProducts] = useLocalStorage(invoiceProductsID, {})
  const [loading, setLoading] = useState(false)
  const [printReceipt, setPrintReceipt] = useState(false)
  const productDetail = useModal()
  const calculatorModal = useModal()
  const { parseAmount } = useParsers()
  const inputRef = useRef(null)
  const selectedPrinter = usePrinters(state => state.selectedPrinter)
  const navigate = useNavigate()

  const [editting, setEditting] = useState(false)

  const showLoading = () => {
    setLoading(true)
  }

  const hideLoading = () => {
    setLoading(false)
  }

  const subtotal = useMemo(() => {
    if (isEmpty(products)) return 0

    const productsArray = Object.values(products)

    const _total = sumBy(productsArray, 'total')

    return _total
  }, [products])

  const resetState = () => {
    setProducts({})
    hideLoading()
    inputRef?.current?.focus()
    setEditting(false)

    console.log('resetState')
  }

  const clearProductList = () => {
    resetState()
  }

  const enableNewBilling = () => {
    clearProductList()

    // toast.success(`Venta completada con éxito`)
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Venta completada con éxito',
      showConfirmButton: false,
      timer: 2000,
      // backdrop: 'transparent',
      customClass: {
        container: 'swal-container',
      },
    })
  }

  const getTheInvoiceDetail = () => {
    if (isEmpty(products)) return []

    const productsArray = Object.values(products)

    const details = productsArray?.map(product => {
      const {
        product_ID,
        product_code,
        product_name,
        qty,
        total,
        sale_price,
        prescription_required,
        stock,
        category,
        laboratory,
        pharmaceutical_form,
        notify_when_sold,
        archive_when_exhausted,
      } = product

      const newCategory = category ? category : ''

      return {
        product_ID,
        product_code,
        product_name,
        laboratory,
        pharmaceutical_form,
        name: product_name,
        qty,
        total,
        sale_price,
        prescription_required,
        prev_stock: stock,
        new_stock: stock - qty,
        category: newCategory,
        notify_when_sold,
        archive_when_exhausted,
      }
    })

    if (isEmpty(details)) return []

    return details
  }

  const atTheEndOfTheSale = () => {
    hideLoading()
    enableNewBilling()
  }

  const saveInvoice = async ({
    payment_info,
    onSuccess = () => {},
    onError = () => {},
  }) => {
    try {
      const productsArray = Object.values(products)

      const pharmacy_ID = selectedPharmacy?.pharmacy_ID

      const prescription_required = productsArray?.some(
        product => product?.prescription_required === true
      )

      const description = getDescription({
        data: productsArray,
        property: 'product_name',
      })

      const affected_products = getAffectedProducts({
        data: productsArray,
        property: 'product_ID',
      })

      const details = getTheInvoiceDetail()

      const user = auth?.currentUser

      const user_ID = user?.uid

      const invoice = {
        new_sales_process: true,
        created_by: user_ID,
        pharmacy_ID,
        prescription_required: prescription_required,
        invoice_amount: subtotal,
        description,
        details,
        affected_products,
        ...payment_info,
      }

      console.log({ invoice })

      //Crear factura
      const invoiceRef = await addDoc(collection(db, 'invoices'), invoice)

      onSuccess({ invoice, user_ID, pharmacy_ID })
    } catch (error) {
      onError({
        errorMessage: 'Error al crear factura',
        error,
        location: 'saveInvoice',
      })
    }
  }

  const saveInvoiceInCash = async ({ payment_info, resetPaymentForm }) => {
    const errorMessage = 'Hubo un error al guardar la factura de contado.'

    try {
      if (isEmpty(products)) return

      showLoading()

      saveInvoice({
        payment_info,
        onSuccess: ({ invoice, user_ID, pharmacy_ID }) => {
          const seller = getUserById({ users, user_ID })

          const is_cash = payment_info?.payment_method === 'Efectivo'

          if (printReceipt) {
            const receiptTemplate = getReceiptTemplate({
              parseAmount,
              invoice: {
                ...invoice,
                seller: seller?.user_name,
                client_payment: is_cash
                  ? parseAmount({
                      value: invoice?.client_payment,
                    })
                  : 0,
                cambio: is_cash
                  ? parseAmount({
                      value: invoice?.cambio,
                    })
                  : 0,
              },
              pharmacy: selectedPharmacy,
              transacctionType: 'Factura',
            })
            printReceiptAction({
              printerId: selectedPrinter,
              template: receiptTemplate,
            })
          }

          resetPaymentForm()

          atTheEndOfTheSale()
        },
        onError: handleError,
        errorMessage,
      })
    } catch (error) {
      handleError({
        error,
        errorMessage,
        location: 'saveInvoiceInCash',
      })
    }
  }

  const handleError = ({ errorMessage = '', error, location = '' }) => {
    hideLoading()
    console.error(`Error in:  ${location}`)

    console.error(error)

    toast.error(errorMessage)
  }

  const saveProform = async ({ payment_info, resetPaymentForm = () => {} }) => {
    const errorMessage = 'Hubo un error al guardar la proforma.'

    try {
      if (isEmpty(products)) return

      showLoading()

      await saveInvoice({
        payment_info,
        onSuccess: ({ invoice, user_ID }) => {
          const seller = getUserById({ users, user_ID })

          const receiptTemplate = getReceiptTemplate({
            parseAmount,
            invoice: {
              ...invoice,
              seller: seller?.user_name,
              client_payment: 0,
              cambio: 0,
            },
            pharmacy: selectedPharmacy,
            transacctionType: 'Proforma',
          })

          printReceiptAction({
            printerId: selectedPrinter,
            template: receiptTemplate,
          })

          resetPaymentForm()

          atTheEndOfTheSale()
        },
        onError: handleError,
        errorMessage,
      })
    } catch (error) {
      handleError({
        error,
        errorMessage,
        location: 'saveProform',
      })
    }
  }

  const saveInvoiceToCredit = async ({
    payment_info,
    resetPaymentForm = () => {},
  }) => {
    const errorMessage = 'Hubo un error al guardar la factura al crédito.'

    try {
      if (isEmpty(products)) return

      showLoading()

      await saveInvoice({
        payment_info,
        onSuccess: ({ invoice, user_ID, pharmacy_ID }) => {
          const seller = getUserById({ users, user_ID })

          const receiptTemplate = getReceiptTemplate({
            parseAmount,
            invoice: {
              ...invoice,
              seller: seller?.user_name,
              client_payment: 0,
              cambio: 0,
            },
            pharmacy: selectedPharmacy,
            transacctionType: 'Factura',
          })

          printReceiptAction({
            printerId: selectedPrinter,
            template: receiptTemplate,
          })

          resetPaymentForm()

          atTheEndOfTheSale()
        },
        onError: handleError,
        errorMessage,
      })
    } catch (error) {
      handleError({
        error,
        errorMessage,
        location: 'saveInvoiceToCredit',
      })
    }
  }

  const processInvoice = ({ payment_info, resetPaymentForm = () => {} }) => {
    console.log('processInvoice')
    console.time('processInvoice')

    console.log({ editting })

    if (editting) {
      toast.error(
        'Por favor, termine de editar la cantidad del producto seleccionado antes de guardar'
      )
    } else if (!editting) {
      const typeOfInvoice = payment_info?.type_of_invoice

      const isAProform = typeOfInvoice === 'Proforma'
      const isAcredit = typeOfInvoice === 'Credito'

      const props = { payment_info, resetPaymentForm }

      if (isAProform) {
        saveProform(props)
      } else if (isAcredit) {
        saveInvoiceToCredit(props)
      } else {
        saveInvoiceInCash(props)
      }

      console.log('updateCompleted')
    }
  }

  const updateProduct = updated_product => {
    const product_code = updated_product?.product_code

    updateProductQty({
      barcode: product_code,
      units_to_buy: updated_product?.qty,
    })

    inputRef?.current?.focus()
  }

  const removeProduct = deleted_product => {
    if (isEmpty(deleted_product)) return

    if (isEmpty(products)) return

    const product_code = deleted_product?.product_code

    const { [product_code]: removedProduct, ...remainingProducts } = products

    setProducts(remainingProducts)

    inputRef?.current?.focus()
  }

  const updateProductQty = ({ barcode, units_to_buy }) => {
    const old_products = products

    const product = old_products[barcode]

    const total = units_to_buy * product?.sale_price

    const updated_product = {
      ...product,
      qty: units_to_buy,
      total: total,
    }

    setProducts({
      ...old_products,
      [barcode]: updated_product,
    })
  }

  const addProduct = async ({
    barcode,
    from_list = false,
    onSuccess = () => {},
    onFailure = () => {},
  }) => {
    try {
      //Buscar producto en el estado local
      const product_on_invoice = products[barcode]

      const EXISTS_IN_CURRENT_INVOICE = !isEmpty(product_on_invoice)
      const DOES_NOT_EXIST_IN_CURRENT_INVOICE = isEmpty(product_on_invoice)

      if (EXISTS_IN_CURRENT_INVOICE) {
        const stock = product_on_invoice?.stock

        const units_to_buy = product_on_invoice?.qty + 1

        const OUT_OF_STOCK = stock < units_to_buy
        const THERE_ARE_STOCKS = stock >= units_to_buy

        if (OUT_OF_STOCK) {
          toast.error(`Solo hay ${stock} ${getUnitLabel(stock)} en stock.`)
        } else if (THERE_ARE_STOCKS) {
          updateProductQty({ barcode, units_to_buy })
        }

        return { productNotFound: false }
      } else if (DOES_NOT_EXIST_IN_CURRENT_INVOICE) {
        const product_found = await searchProductInDB({
          code: barcode,
          selectedPharmacy,
        })

        const THE_PRODUCT_EXISTS = Boolean(product_found?.exists) === true
        const THE_PRODUCT_DOES_NOT_EXIST =
          Boolean(product_found?.exists) === false

        if (THE_PRODUCT_EXISTS) {
          const product = {
            product_ID: product_found?.id,
            ...product_found?.data(),
          }

          const OUT_OF_STOCK = product?.stock < 1
          const THERE_ARE_STOCKS = product?.stock >= 1

          if (OUT_OF_STOCK) {
            toast.error('Este producto está agotado')
          } else if (THERE_ARE_STOCKS) {
            //Preguntar si el producto ya habia sido agregado a esta factura
            const initial_qty = 1
            const current_price = product?.current_price
            const total = initial_qty * current_price
            const product_ID = product?.product_ID
            const product_code = product?.product_code

            const new_product = {
              product_ID,
              product_code,
              product_name: product?.product_name,
              stock: product?.stock,
              total: total,
              qty: initial_qty,
              sale_price: current_price,
              prescription_required: product?.prescription_required,
              category: product?.category ? product?.category : '',
              laboratory: product?.laboratory,
              pharmaceutical_form: product?.pharmaceutical_form,
              location: product?.location || '',
              default_batch: {
                ...product?.default_batch,
                expiration_date: product?.default_batch.has_an_expiration_date
                  ? format(
                      product?.default_batch?.expiration_date.toDate(),
                      'yyyy-MM-dd'
                    )
                  : '',
              },
              low_stock: product?.low_stock,
              excessive_stock: product?.excessive_stock,
              out_of_stock: product?.stock < 1,
              stock_status: getStockStatus({
                stock: product?.stock,
                low_stock: product?.low_stock,
                excessive_stock: product?.excessive_stock,
              }),

              notify_when_sold: product?.notify_when_sold || false,
              archive_when_exhausted: product?.archive_when_exhausted || false,
            }

            const prevValue = products

            setProducts({ [product_code]: new_product, ...prevValue })

            toast('Producto agregado')
          }

          return { productNotFound: false }
        } else if (THE_PRODUCT_DOES_NOT_EXIST) {
          //SI el producto no existe en la BD

          toast.error('Este producto no está en su inventario.')
          onFailure()

          return { productNotFound: true }
        }
      }

      onSuccess()
    } catch (err) {
      console.error(err)
    }
  }

  const onBarcodeScanWithScanner = ({
    productCode,
    onSuccess = () => {},
    onFailure = () => {},
  }) => {
    if (!productCode) return

    addProduct({
      barcode: productCode,
      onSuccess,
      onFailure,
    })
  }

  const addProductFromList = product => {
    if (isEmpty(product)) return

    addProduct({
      barcode: product?.product_code,
      from_list: true,
      onSuccess: () => {
        inputRef?.current?.focus()
      },
    })
  }

  const handlePrintReceipt = event => {
    setPrintReceipt(event.target.checked)
  }

  const invoiceProductsProps = {
    products: Object.values(products),
    updateProduct,
    removeProduct,
    setEditting,
  }

  const invoiceResumeProps = {
    productsCount: !isEmpty(products) ? Object.keys(products)?.length : 0,
    subtotal,
    processInvoice,
    clearProductList,
    printReceipt,
    handlePrintReceipt,
    editting,
  }

  useEffect(() => {
    const handleBeforeUnload = event => {
      event.preventDefault()

      sessionStorage.setItem('isReloaded', 'true')

      event.returnValue = '' // Necesario para algunos navegadores
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    const isReloaded = sessionStorage.getItem('isReloaded')

    if (isReloaded === 'true') {
      navigate(HOME)

      sessionStorage.setItem('isReloaded', 'false')
    }
  }, [])

  return {
    products,
    subtotal,
    updateProduct,
    removeProduct,
    loading,
    addProductFromList,
    productDetail,
    onBarcodeScanWithScanner,
    inputRef,
    processInvoice,
    users,
    invoiceResumeProps,
    invoiceProductsProps,
    calculatorModal,
  }
}
