import React, { useState } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField,
  Typography,
  Select,
  Card,
  useTheme,
  CardContent,
  Button,
  Tabs,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2
import MlIcon from '@mui/icons-material/Vaccines'
import DropIcon from '@mui/icons-material/WaterDrop'
import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid'

import randomKey from 'utils/randomKey'
import FlexContainer from 'components/FlexContainer'
import useDosageCalculator from './useDosageCalculator'
import { floor, isEmpty } from 'lodash'

const FrequencyOptions = [
  {
    label: 'Cada 3 horas',
    value: 8,
  },
  {
    label: 'Cada 4 horas',
    value: 6,
  },
  {
    label: 'Cada 6 horas',
    value: 4,
  },
  {
    label: 'Cada 8 horas',
    value: 3,
  },
  {
    label: 'cada 12 horas',
    value: 2,
  },
  {
    label: 'Una vez al día',
    value: 1,
  },
]

export default function DosageCalculator({
  visible = true,
  onClose = () => {},
}) {
  const theme = useTheme()
  const {
    weightIn,
    setWeightIn,
    patientWeight,
    setPatientWeight,
    concentrationInMG,
    setConcentrationInMG,
    concentrationInML,
    setConcentrationInML,
    doctorIndication,
    setDoctorIndication,
    results,
    calculateDosage,
    resultIn,
    setResultIn,
    dayOrDose,
    setDayOrDose,
    frequency,
    setFrequency,
    Units,
  } = useDosageCalculator()

  return (
    <Dialog
      keepMounted
      open={visible}
      onClose={onClose}
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle>Calcular dosis</DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>
            Datos del paciente
          </Typography>

          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Peso en</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  size={'small'}
                  value={weightIn}
                  onChange={event => {
                    const value = event.target.value

                    setWeightIn(value)
                  }}
                  tabIndex={100}
                >
                  <MenuItem value={'Kg'}>Kilogramo (Kg)</MenuItem>
                  <MenuItem value={'Lb'}>Libra (Lb)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <TextField
                fullWidth
                type="number"
                variant="filled"
                size={'small'}
                label={`Peso del paciente`}
                inputProps={{
                  min: 0,
                }}
                value={String(patientWeight)}
                onChange={event => {
                  setPatientWeight(Number(event?.target?.value))
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={'end'}>{weightIn}</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>
          Presentación del farmaco
        </Typography>

        <Box sx={{ mb: 2 }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              mb: 1,
            }}
          >
            Ejemplo: 200mg / 5ml
          </Typography>
          <FlexContainer>
            <TextField
              size="small"
              variant={'filled'}
              label={'Concentración'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'end'}>mg</InputAdornment>
                ),
              }}
              value={String(concentrationInMG)}
              onChange={event => {
                setConcentrationInMG(Number(event?.target?.value))
              }}
            />
            <Typography
              variant="body1"
              sx={{
                whiteSpace: 'nowrap',
                mx: 1,
              }}
            >
              /
            </Typography>
            <TextField
              size="small"
              variant={'filled'}
              label={'Por cada'}
              value={String(concentrationInML)}
              onChange={event => {
                setConcentrationInML(Number(event?.target?.value))
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'end'}>ml</InputAdornment>
                ),
              }}
            />
          </FlexContainer>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>
            {/* Orientación médica */}
            Dosis Estandar
          </Typography>

          <Grid container spacing={2}>
            <Grid xs={6}>
              <TextField
                size="small"
                variant={'filled'}
                label={'Concentración'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={'end'}>mg</InputAdornment>
                  ),
                }}
                value={String(doctorIndication)}
                onChange={event => {
                  setDoctorIndication(Number(event?.target?.value))
                }}
              />
            </Grid>{' '}
            <Grid xs={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Por cada</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  size={'small'}
                  value={dayOrDose}
                  onChange={event => {
                    const value = event.target.value

                    setDayOrDose(value)
                  }}
                >
                  <MenuItem value={'DOSE'}>Dosis</MenuItem>
                  <MenuItem value={'DAY'}>Dia</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {dayOrDose === 'DAY' ? (
            <Box sx={{ mt: 2 }}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Frecuencia</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  size={'small'}
                  value={frequency}
                  onChange={event => {
                    const value = event.target.value

                    setFrequency(value)
                  }}
                  tabIndex={100}
                >
                  {FrequencyOptions?.map(option => {
                    return (
                      <MenuItem key={randomKey()} value={option?.value}>
                        {option?.label}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          ) : null}
        </Box>

        {/* <Divider /> */}
        {!isEmpty(results) ? (
          <Card
            variant="outlined"
            sx={{
              backgroundColor: theme.palette.neutral['white'],
              padding: 1,
            }}
          >
            <ToggleButtonGroup
              fullWidth
              size={'small'}
              color="primary"
              value={resultIn}
              exclusive
              onChange={(_, value) => {
                if (value) {
                  setResultIn(value)
                }
              }}
            >
              <ToggleButton value="ml">
                <MlIcon />
                Ml
              </ToggleButton>
              <ToggleButton value="drops">
                <DropIcon />
                Gotas
              </ToggleButton>
              <ToggleButton value="teaspoons">
                <MedicationLiquidIcon />
                Cucharaditas
              </ToggleButton>
            </ToggleButtonGroup>

            <CardContent>
              <Typography variant="body1" align="center">
                La dosis recomendada es
              </Typography>

              <Typography variant="h6" align="center">
                {floor(results[resultIn], 2)} {Units[resultIn]}
              </Typography>
              <Typography
                variant="caption"
                align="center"
                color="text.secondary"
                sx={{ display: 'block' }}
              >
                {results?.label}
              </Typography>
            </CardContent>
          </Card>
        ) : null}
        <Button
          sx={{ mt: 2 }}
          size="large"
          variant="contained"
          fullWidth
          onClick={calculateDosage}
        >
          Calcular dosis
        </Button>
      </DialogContent>
    </Dialog>
  )
}
