import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useLocations = create(
  persist(
    (set, get) => ({
      locations: {},
      setLocations: locations => set({ locations }),
      addLocation: location =>
        set(state => ({
          locations: { ...state.locations, [location]: location },
        })),
      getLocationByName: name => get().locations[name],
      clearLocations: () => set({ locations: {} }),
    }),
    {
      name: 'locations',
    }
  )
)
