import { PaginateHits } from 'components/PaginateHits'
import { auth } from 'config/firebase'
import useProductsList from 'hooks/useProductList'
import React, { useRef, useState } from 'react'
import { InstantSearch, Configure } from 'react-instantsearch'
import Dialog from '@mui/material/Dialog'
import {
  Box,
  DialogContent,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import SearchBox from 'components/SearchBox'
import Paper from '@mui/material/Paper'
import Draggable from 'react-draggable'
import LaboratoryFilter from 'components/ChipFilters/LaboratoryFilter'
import PharmaceuticalForm from 'components/ChipFilters/PharmaceuticalForm'
import StockFilter from 'components/ChipFilters/StockFilter'

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

export default function SearchProductModal({
  onClose = () => {},
  onSelect = () => {},
  initialKeyword = '',
}) {
  const { client, REACT_APP_ALGOLIA_INDEX, pharmacy_ID } = useProductsList()
  const [closeOnSelect, setCloseOnSelect] = useState(true)
  const [showFilters, setShowFilters] = useState(true)
  const searchbarRef = useRef(null)

  const theme = useTheme()
  const contentRef = useRef(null)

  const user = auth?.currentUser

  const scrollTop = () => {
    contentRef.current.scrollTo(0, 0)
  }

  const scrollBottom = ({ index, isLast }) => {
    if (isLast) {
      contentRef.current.scrollTo(0, 500)
    } else {
      contentRef.current.scrollTo(0, index * 60)
    }
  }

  return (
    <Dialog
      open={true}
      keepMounted
      onClose={onClose}
      maxWidth="sm"
      fullWidth={true}
      PaperComponent={PaperComponent}
      sx={{
        '& .MuiDialog-paper': {
          height: '700px',
        },
      }}
    >
      <InstantSearch
        indexName={REACT_APP_ALGOLIA_INDEX}
        searchClient={client}
        routing={true}
        insights={true}
      >
        <Configure
          analytics={true}
          filters={`pharmacy_ID:${pharmacy_ID} AND archived=0`}
          userToken={user?.uid}
        />
        <Box sx={{ cursor: 'move' }} id="draggable-dialog-title">
          <SearchBox
            placeholder="Buscar productos"
            initialKeyword={initialKeyword}
            {...{
              closeOnSelect,
              setCloseOnSelect,
              showFilters,
              setShowFilters,
              searchbarRef,
            }}
          />
          <Divider />
          {showFilters ? (
            <Stack direction="row" spacing={1} sx={{ ml: 1, mr: 1, mt: 1 }}>
              <LaboratoryFilter />
              <PharmaceuticalForm />
              <StockFilter hideSearchbar />
            </Stack>
          ) : null}
        </Box>

        <DialogContent ref={contentRef}>
          <PaginateHits
            scrollTop={scrollTop}
            scrollBottom={scrollBottom}
            onSelect={product => {
              onSelect({
                product,
                onSuccess: () => {
                  if (closeOnSelect) {
                    onClose()
                  } else {
                    searchbarRef?.current?.focus()
                  }
                },
              })
            }}
          />
        </DialogContent>
      </InstantSearch>
    </Dialog>
  )
}
