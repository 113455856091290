import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'
import useEditProduct from './useEditProduct'
import { Controller } from 'react-hook-form'
import LocationSelector from 'components/LocationSelector'
import FlexContainer from 'components/FlexContainer'
import Draggable from 'react-draggable'
import Paper from '@mui/material/Paper'
import { cropText } from 'utils/cropText'

const inputSpace = 0.5
const blockSpace = 2
const groupSpace = 3
const inputVariant = 'outlined'
const inputSize = 'medium'

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

export default function EditProductModal({ product, onUpdate, onClose }) {
  const {
    getFormProps,
    errors,
    watch,
    setValue,
    seeMore,
    toggleSeeMore,
    handleSubmit,
    onSubmit,
    archiveWhenExhausted,
    notifyWhenSold,
    toggleArchiveWhenExhausted,
    toggleNotifyWhenSold,
  } = useEditProduct({ product, onClose, onUpdate })

  const hasError = key => {
    return Boolean(errors[key])
  }

  const description = `${product?.pharmaceutical_form} - ${product?.laboratory}`

  return (
    <Dialog
      open={true}
      keepMounted
      onClose={onClose}
      maxWidth="xs"
      fullWidth={true}
      PaperComponent={PaperComponent}

      // sx={{
      //   '.MuiDialog-paper': {},
      // }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ cursor: 'move' }} id="draggable-dialog-title">
          <Typography variant="h6">
            {cropText(product?.product_name)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>

          {/* <ScanInput
            action={({ productCode, onFailure }) => {
              console.log({ productCode, onFailure })

              // onBarcodeScanWithScanner({ productCode, onFailure })
            }}
            onSelect={product => {
              // const result = addProductFromList(product)
              // return result
            }}
            // inputRef={inputRef}
          /> */}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {/* <Card
            variant="outlined"
            sx={theme => ({
              padding: theme.spacing(2),
              // margin: theme.spacing(2),
              marginBottom: theme.spacing(1),
              borderRadius: theme.spacing(1),
              backgroundColor: theme.palette.action.hover,
              // border: 'none',
              border: `1px solid ${theme.palette.divider}`,
            })}
          >
            <Typography variant="subtitle1">{product?.product_name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </Card> */}
          <Box sx={{ mb: groupSpace }}>
            <LocationSelector
              size={inputSize}
              variant={inputVariant}
              value={watch('location')}
              setValue={value => {
                setValue('location', value)
              }}
            />
          </Box>

          <Typography variant="subtitle1" sx={{ mt: groupSpace, mb: 2 }}>
            Stock
          </Typography>

          <FlexContainer mb={blockSpace}>
            <Controller
              {...getFormProps({
                name: 'manual_min_stock',
                rules: { required: true },
              })}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant={inputVariant}
                  size={inputSize}
                  inputMode="number"
                  label="Stock minimo"
                  type="number"
                  error={hasError('manual_min_stock')}
                  helperText={
                    hasError('manual_min_stock') ? 'Campo requerido' : ''
                  }
                />
              )}
            />

            <Box sx={{ width: '24px' }}></Box>

            <Controller
              {...getFormProps({
                name: 'manual_max_stock',
                rules: { required: true },
              })}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant={inputVariant}
                  size={inputSize}
                  label="Stock maximo"
                  inputMode="number"
                  type="number"
                  error={hasError('manual_max_stock')}
                  helperText={
                    hasError('manual_max_stock') ? 'Campo requerido' : ''
                  }
                />
              )}
            />
          </FlexContainer>

          <Card
            variant="outlined"
            sx={theme => ({
              padding: theme.spacing(2),
              marginBottom: theme.spacing(1),
              borderRadius: '12px',

              border: `1px solid ${theme.palette.divider}`,
            })}
          >
            <Typography variant="body1" sx={{ fontWeight: '600', mb: 1 }}>
              Al agotarse el stock
            </Typography>
            <FormControlLabel
              tabIndex={100}
              control={
                <Switch
                  checked={archiveWhenExhausted}
                  onChange={toggleArchiveWhenExhausted}
                />
              }
              label="Archivar al agotarse"
            />

            <FormControlLabel
              tabIndex={100}
              control={
                <Switch
                  checked={notifyWhenSold}
                  onChange={toggleNotifyWhenSold}
                />
              }
              label="Notificar por WhatsApp"
            />
          </Card>

          <FormControlLabel
            tabIndex={100}
            control={<Switch checked={seeMore} onChange={toggleSeeMore} />}
            label="Edición avanzada"
          />
          {seeMore ? (
            <Box>
              <Box sx={{ mt: groupSpace }}>
                <Controller
                  {...getFormProps({
                    name: 'product_code',
                    rules: { required: true },
                  })}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size={inputSize}
                      variant={inputVariant}
                      label="Código de barras"
                      error={hasError('product_code')}
                      helperText={
                        hasError('product_code') ? 'Campo requerido' : ''
                      }
                    />
                  )}
                />
              </Box>
              <Box sx={{ my: groupSpace }}>
                <Controller
                  {...getFormProps({
                    name: 'product_name',
                    rules: { required: true },
                  })}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size={inputSize}
                      variant={inputVariant}
                      label="Nombre del producto"
                      error={hasError('product_name')}
                      helperText={
                        hasError('product_name') ? 'Campo requerido' : ''
                      }
                    />
                  )}
                />
              </Box>
              {/* <Box sx={{ my: groupSpace }}>
                <PharmaceuticalFormSelector
                  size={inputSize}
                  variant={inputVariant}
                  value={watch('pharmaceutical_form')}
                  setValue={value => {
                    setValue('pharmaceutical_form', value)
                  }}
                />
              </Box>
              <Box sx={{ my: groupSpace }}>
                <LaboratorySelector
                  size={inputSize}
                  variant={inputVariant}
                  value={watch('laboratory')}
                  setValue={value => {
                    setValue('laboratory', value)
                  }}
                />
              </Box> */}
            </Box>
          ) : null}

          {/* <Box>
          <Typography
            variant="subtitle1"
            sx={{ mt: groupSpace, mb: inputSpace }}
          >
            Categorías y usos
          </Typography>
          <Box>
            <FlexContainer justifyContent="flex-start">
              <Typography
                variant="subtitle1"
                sx={{ mt: groupSpace, mb: inputSpace }}
              >
                Categorías y usos
              </Typography>
            </FlexContainer>
            <Controller
              {...getFormProps({
                name: 'category',
                rules: { required: true },
              })}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ mb: groupSpace }}
                  multiline={true}
                  fullWidth
                  size={inputSize}
                  variant={inputVariant}
                  label="Categoria"
                  error={hasError('category')}
                  helperText={hasError('category') ? 'Campo requerido' : ''}
                />
              )}
            />
            <Controller
              {...getFormProps({
                name: 'serves_to',
                rules: { required: true },
              })}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline={true}
                  rows={4}
                  fullWidth
                  size={inputSize}
                  variant={inputVariant}
                  label="¿Para qué sirve este producto?"
                  error={hasError('serves_to')}
                  helperText={hasError('serves_to') ? 'Campo requerido' : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          height: '100px',
                          maxHeight: '100px',
                          alignItems: 'flex-start',
                        }}
                      >
                        <IconButton
                          size="small"
                          component="button"
                          href=""
                          sx={theme => ({
                            background: theme.palette.neutral[100],
                          })}
                        >
                          <GoogleIcon color="primary" fontSize="14px" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Box> */}
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant="contained" size="large" type="submit">
            Actualizar producto
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
