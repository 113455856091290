import { useState, useEffect } from 'react'
import aa from 'search-insights'
import { isEmpty } from 'lodash'
import useInvitations from 'hooks/users/useInvitations'
import { auth, db } from 'config/firebase'
import { getDataFromRef } from 'utils/getDataFromRef'
import { doc, onSnapshot, query, where, collection } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'
import useAuthContext from 'features/auth/hooks/useAuthContext'
import usePharmacy from 'hooks/usePharmacy'
import useLocationsSelector from 'hooks/selectors/useLocationsSelector'

export default function useApp() {
  const [initializing, setInitializing] = useState(true)
  const [pharmacies, setPharmacies] = useState([])
  const [changePassword, setChangePassword] = useState(false)
  const { isAuth } = useAuthContext()
  const { updateLocationList } = useLocationsSelector()
  const currentUser = auth?.currentUser

  const {
    changePharmacy,
    changePrimaryCurrency,
    changeSecondaryCurrency,
    selectedPharmacy,
    updateUserList,
    updatePharmacyList,
    signOut,
  } = usePharmacy()
  //   const { invitations, declineInvitation, acceptTheInvitation } =
  //     useInvitations()

  //Obtener las farmacias
  useEffect(() => {
    try {
      if (!Boolean(isAuth)) return

      const onResult = querySnapshot => {
        if (querySnapshot?.empty) {
          setPharmacies([])
        } else {
          const _pharmacies = []

          querySnapshot.forEach(documentSnapshot => {
            const pharmacyData = documentSnapshot.data()

            const _pharmacy = {
              docID: documentSnapshot.id,
              ...pharmacyData,
              label: pharmacyData?.pharmacy_name,
              value: documentSnapshot.id,
            }

            _pharmacies.push(_pharmacy)
          })

          if (!isEmpty(_pharmacies)) {
            const currentPharmacy = _pharmacies[0]

            changePharmacy(currentPharmacy)
          }

          updatePharmacyList(_pharmacies)

          setPharmacies(_pharmacies)
        }
      }

      const user_ID = currentUser?.uid
      if (!Boolean(user_ID)) return

      const q = query(
        collection(db, 'pharmacies'),
        where('users', 'array-contains', user_ID)
      )

      const subscriber = onSnapshot(q, onResult, error => {
        console.error(error)
      })

      return () => subscriber()
    } catch (e) {
      console.error(e)
    }
  }, [isAuth, currentUser])

  useEffect(() => {
    if (!Boolean(isAuth)) return

    const user_ID = currentUser?.uid
    if (!Boolean(user_ID)) return

    aa('setAuthenticatedUserToken', user_ID)
    aa('setUserToken', user_ID)
  }, [isAuth, currentUser])

  //Setear moneda primaria y secundaria
  useEffect(() => {
    const { pharmacy_primary_currency, pharmacy_secondary_currency } =
      selectedPharmacy

    changePrimaryCurrency(pharmacy_primary_currency)
    changeSecondaryCurrency(pharmacy_secondary_currency)
  }, [selectedPharmacy])

  const getUserList = async () => {
    try {
      if (isEmpty(selectedPharmacy)) {
        updateUserList([])
      } else {
        const usersIds = selectedPharmacy?.users

        const _users = []

        for await (let usersId of usersIds) {
          const userRef = doc(db, 'users', usersId)

          const userData = await getDataFromRef(userRef)

          if (!isEmpty(userData)) {
            const { user_ID, user_email, user_name } = userData

            _users.push({
              user_ID,
              user_email,
              user_name,
            })
          }
        }

        updateUserList(_users)
      }
    } catch (e) {
      console.error(e)
    }
  }

  //Guardar la lista de usuarios de esta farmacia
  useEffect(() => {
    getUserList()
  }, [selectedPharmacy?.pharmacy_ID])

  //Verificar si el usuario tiene una contraseña temporal
  useEffect(() => {
    if (!Boolean(isAuth)) return

    const user_ID = currentUser?.uid
    if (!Boolean(user_ID)) return

    const subscriber = onSnapshot(
      doc(db, 'users', user_ID),
      documentSnapshot => {
        if (documentSnapshot.exists) {
          const userData = documentSnapshot.data()

          setChangePassword(userData?.change_password)
        } else {
          setChangePassword(false)
        }
      }
    )

    // Stop listening for updates when no longer required
    return () => subscriber()
  }, [isAuth, currentUser])

  //Actualizar la lista de locations de la farmacia
  useEffect(() => {
    updateLocationList()
  }, [selectedPharmacy])

  return {}
}
