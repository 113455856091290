import {
  Box,
  Card,
  CardContent,
  InputAdornment,
  Typography,
} from '@mui/material'
import { CalendarIcon, MobileDatePicker } from '@mui/x-date-pickers'
import Container from 'components/Container'
import ListOfInvoices from 'features/home/components/ListOfInvoices'
import ProductsSold from 'features/home/components/ProductsSold'
import SalesSummary from 'features/home/components/SalesSummary'
import React, { useState } from 'react'

export default function Home() {
  const [date, setDate] = useState(new Date())
  const isToday = date.toDateString() === new Date().toDateString()
  const isYesterday =
    date.toDateString() ===
    new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()

  return (
    <Container>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '2fr 1fr',
          gridGap: '16px',
        }}
      >
        <Card>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Resumen de ventas
            </Typography>
            <Box sx={{ mb: 2 }}>
              <MobileDatePicker
                label=""
                // closeOnSelect
                disableFuture
                defaultValue={date}
                value={date}
                maxDate={new Date()}
                format="EEEE d MMM yyyy"
                slotProps={{
                  toolbar: { toolbarFormat: 'EEE d MMM yyyy', hidden: false },
                  textField: {
                    hiddenLabel: true,
                    fullWidth: true,
                    variant: 'filled',
                    size: 'small',
                    InputProps: {
                      startAdornment: isToday ? (
                        <InputAdornment position={'start'}>Hoy:</InputAdornment>
                      ) : isYesterday ? (
                        <InputAdornment position={'start'}>
                          Ayer:
                        </InputAdornment>
                      ) : null,
                      endAdornment: (
                        <InputAdornment position={'end'}>
                          <CalendarIcon />
                        </InputAdornment>
                      ),
                    },
                  },
                }}
                onAccept={value => {
                  setDate(value)
                }}
              />
            </Box>
            <SalesSummary date={date} />

            <Box sx={{ mt: 2 }}>
              <ListOfInvoices date={date} />
            </Box>
          </CardContent>
        </Card>
        <ProductsSold date={date} />
      </Box>
    </Container>
  )
}
