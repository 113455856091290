import React, { useState } from 'react'
import {
  Box,
  Card,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  Switch,
  TextField,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import FlexContainer from 'components/FlexContainer'
import { addYears, endOfYear, startOfYear } from 'date-fns'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import CounterButton from 'components/CounterButton'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import { Controller } from 'react-hook-form'
import { isEmpty } from 'lodash'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'

const itemSpace = '10px'
const inputSize = 'medium'
const inputVariant = 'outlined'
const conectorStyle = 'dashed'

export default function BatchItem({
  count,
  isLast,
  isFirst,
  theme,
  conectorColor,
  primaryCurrency,
  formProps,
  field,
  index,
  removeBatchItem,
}) {
  const { control, errors, setValue, watch } = formProps

  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getFormProps = ({ name, rules = { required: true } }) => {
    const fieldName = `batch.${index}.${name}`

    return {
      name: fieldName,
      control,
      rules,
    }
  }

  const hasError = name => {
    try {
      if (isEmpty(errors)) return false

      const errorData = errors?.batch
      if (isEmpty(errorData)) return false
      if (!Boolean(name)) return false

      const errorBatch = errorData[index]

      if (isEmpty(errorBatch)) return false

      const result = Boolean(errorBatch[name])

      return result
    } catch (e) {
      console.error('Error en hasError ')
      console.error(e)

      return false
    }
  }

  const toggleShowExpirationDate = event => {
    const checked = event.target.checked

    // setShowExpiration(value)

    const fieldName = `batch.${index}.has_an_expiration_date`

    setValue(fieldName, checked)
  }

  const showExpiration = Boolean(watch(`batch.${index}.has_an_expiration_date`))

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector
          sx={{
            background: 'transparent',
            border: `1px ${conectorStyle} ${conectorColor}`,
          }}
        />
      </TimelineSeparator>

      <TimelineContent
        component={'div'}
        onContextMenu={event => {
          event.preventDefault()

          handleMenu(event)
        }}
        sx={{
          padding: 0,
          mt: isFirst ? 2 : 0,
          mb: isLast ? 2 : 0,
        }}
      >
        <FlexContainer justifyContent="space-start">
          <Box
            sx={{
              width: '20px',
              height: '1px',
              border: `1px ${conectorStyle} ${conectorColor}`,
            }}
          ></Box>
          <Card
            key={field.id}
            variant="filled"
            sx={{
              width: '100%',
              backgroundColor: theme.palette.neutral['100'],
              padding: '8px 16px 6px 16px',
              borderRadius: 1,
              mb: 2,
            }}
          >
            <FlexContainer>
              <Typography
                variant="subtitle1"
                sx={{ mb: 1, fontWeight: '600' }}
              >{`Lote ${count}`}</Typography>
              <IconButton onClick={handleMenu} tabIndex={100}>
                <MoreVertIcon />
              </IconButton>
            </FlexContainer>
            <FlexContainer mb={2}>
              <Box>
                <Typography sx={{ mb: itemSpace }} variant="subtitle1">
                  Numero de Lote
                </Typography>

                <Controller
                  {...getFormProps({ name: 'batch_code' })}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      hiddenLabel
                      label=""
                      variant={inputVariant}
                      size={inputSize}
                      error={hasError('batch_code')}
                      tabIndex={1}
                    />
                  )}
                />
              </Box>
              <Box sx={{ ml: 1, mr: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showExpiration}
                      onChange={toggleShowExpirationDate}
                      tabIndex={200}
                    />
                  }
                  label="Fecha de vencimiento"
                />

                {showExpiration ? (
                  <Controller
                    {...getFormProps({
                      name: 'expiration_date',
                      rules: { required: showExpiration },
                    })}
                    render={({ field }) => (
                      <MobileDatePicker
                        {...field}
                        disabled={!showExpiration}
                        label=""
                        closeOnSelect
                        minDate={startOfYear(new Date())}
                        maxDate={endOfYear(addYears(new Date(), 11))}
                        format="MMM yyyy"
                        slotProps={{
                          monthButton: base => {
                            const monthNumber = base?.value + 1

                            return {
                              children: `${monthNumber}-${base?.children}`,
                            }
                          },
                          textField: {
                            hiddenLabel: true,
                            fullWidth: true,
                            variant: inputVariant,
                            size: inputSize,
                            error: hasError('expiration_date'),
                            InputProps: showExpiration
                              ? {
                                  endAdornment: (
                                    <InputAdornment position={'end'}>
                                      <CalendarIcon />
                                    </InputAdornment>
                                  ),
                                }
                              : null,
                          },
                        }}
                        views={['month', 'year']}
                        tabIndex={2}
                      />
                    )}
                  />
                ) : (
                  <TextField
                    variant={inputVariant}
                    size={inputSize}
                    fullWidth
                    disabled
                    value="No tiene caducidad"
                  />
                )}
              </Box>
              <Box>
                <Typography sx={{ mb: itemSpace }} variant="subtitle1">
                  Precio de venta
                </Typography>

                <Controller
                  {...getFormProps({ name: 'sale_price' })}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      hiddenLabel
                      variant={inputVariant}
                      size={inputSize}
                      label=""
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position={'start'}>
                            {primaryCurrency?.simbol}
                          </InputAdornment>
                        ),
                      }}
                      error={hasError('sale_price')}
                      tabIndex={5}
                    />
                  )}
                />
              </Box>
            </FlexContainer>
            <FlexContainer mb={2}>
              <CounterButton
                tabIndex={3}
                min={1}
                deleteAction={false}
                value={Number(watch(`batch.${index}.stock`))}
                onChangeValue={qty => {
                  const fieldName = `batch.${index}.stock`

                  setValue(fieldName, qty)
                }}
              />
            </FlexContainer>
          </Card>
        </FlexContainer>
      </TimelineContent>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            removeBatchItem(index)
          }}
        >
          <ListItemIcon>
            <DeleteIcon color="error" fontSize="small" />
          </ListItemIcon>

          <ListItemText color="error">Remover lote</ListItemText>
        </MenuItem>
      </Menu>
    </TimelineItem>
  )
}
