import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
} from '@mui/material'
import FlexContainer from 'components/FlexContainer'
import { isEmpty, sumBy } from 'lodash'
import React, { useRef } from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineContent from '@mui/lab/TimelineContent'
import Add from '@mui/icons-material/AddCircleOutlineOutlined'
import usePharmacy from 'hooks/usePharmacy'
import useReceiveBatch from './useReceiveBatch'
import BatchItem from '../BatchItem'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Edit, Save } from '@mui/icons-material'
import useParsers from 'hooks/useParsers'
import { cropText } from 'utils/cropText'
import ChangePriceDialog from '../ChangePriceDialog'
import LocationsDialog from 'components/LocationsDialog'

export default function ReceiveBatch({
  currentProduct,
  inputRef,
  setCurrentProduct,
  isNewProduct = false,
  createNewProduct = () => {},
  onPrevious = () => {},
  resetNewProduct = () => {},
  contextOfUse = 'RECEIVE_BATCH',
}) {
  const theme = useTheme()
  const { primaryCurrency } = usePharmacy()
  const { parseAmount } = useParsers()

  const {
    addNewBatch,
    onSubmit,
    formProps,
    removeBatchItem,
    loading,
    newPrice,
    setNewPrice,
    automaticPrice,
    setAutomaticPrice,
    newLocation,
    setNewLocation,
    changePriceModal,
    changeLocationModal,
  } = useReceiveBatch({
    inputRef,
    product: currentProduct,
    setProduct: setCurrentProduct,
    resetNewProduct,
    contextOfUse,
    isNewProduct,
  })

  const { fields, handleSubmit, values } = formProps

  const submitTimeoutRef = useRef(null)
  const isSubmittingRef = useRef(false)

  const onSave = data => {
    if (isSubmittingRef.current) return

    if (submitTimeoutRef.current) {
      clearTimeout(submitTimeoutRef.current)
    }

    isSubmittingRef.current = true

    submitTimeoutRef.current = setTimeout(() => {
      isSubmittingRef.current = false
    }, 3000)

    if (contextOfUse === 'NEW_PRODUCT' && isNewProduct) {
      createNewProduct({
        newPrice,
        newLocation,
        saveBatches: () => {
          onSubmit(data)
        },
      })
    } else {
      onSubmit(data)
    }
  }

  const conectorColor = theme.palette.action.disabled
  const conectorStyle = 'dashed'

  const currentStock = isNewProduct ? 0 : Number(currentProduct?.stock)

  const unitsToReceive = sumBy(values, 'stock')

  const newStock = currentStock + unitsToReceive

  const currentPrice = parseAmount({
    value: newPrice,
    showCode: false,
  })

  if (!isNewProduct && isEmpty(currentProduct))
    return (
      <Box
        sx={{
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            borderRadius: '100px',
            width: '100px',
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.primary2['100'],
            mt: 10,
            mb: 2,
          }}
        >
          <Inventory2OutlinedIcon fontSize="large" color="primary" />
        </Box>

        <Typography
          variant="subtitle1"
          color="text.primary"
          sx={{ fontWeight: '600' }}
        >
          Escanee o elija un producto de la lista
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Una vez que seleccione un producto podrá recibir lotes para actualizar
          el stock.
        </Typography>
      </Box>
    )

  return (
    <Card variant="elevation" elevation={0} sx={{ mt: 1 }}>
      <form onSubmit={handleSubmit(onSave)}>
        <Box sx={{ pt: 1, px: 1, position: 'sticky' }}>
          <FlexContainer>
            <Box>
              <Typography
                variant="subtitle1"
                color="text.primary"
                sx={{
                  fontWeight: '600',
                }}
              >
                {cropText(currentProduct?.product_name, 40)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {cropText(
                  `${currentProduct?.pharmaceutical_form || ''} ${
                    currentProduct?.laboratory || ''
                  }`
                )}
              </Typography>

              <FlexContainer mt={1} mb={1} justifyContent="flex-start">
                <Button
                  sx={{ textTransform: 'capitalize', mr: 2 }}
                  size="small"
                  variant="text"
                  endIcon={<Edit />}
                  onClick={changePriceModal.open}
                  tabIndex={100}
                >
                  {`Precio: ${currentPrice}`}
                </Button>
                <Button
                  sx={{ textTransform: 'capitalize' }}
                  size="small"
                  variant="text"
                  endIcon={<Edit />}
                  onClick={changeLocationModal.open}
                  tabIndex={100}
                >
                  {`Ubicación: ${newLocation || 'N/D'}`}
                </Button>
              </FlexContainer>
            </Box>

            <Box sx={{ padding: '0 16px 8px 16px' }}>
              <FlexContainer>
                <Box>
                  <Typography
                    variant="caption"
                    color="text.primary"
                    align="center"
                  >
                    Stock actual
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.primary"
                    align="center"
                  >
                    {currentStock}
                  </Typography>
                </Box>

                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

                <Box>
                  <Typography
                    variant="caption"
                    color="text.primary"
                    align="center"
                    sx={{
                      fontWeight: '700',
                    }}
                  >
                    Unidades a recibir
                  </Typography>
                  <Typography
                    variant="h6"
                    align="center"
                    color="text.primary"
                    sx={{
                      fontWeight: '700',
                    }}
                  >
                    {unitsToReceive}
                  </Typography>
                </Box>

                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

                <Box>
                  <Typography
                    variant="caption"
                    color="text.primary"
                    align="center"
                  >
                    Stock Nuevo
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.primary"
                    align="center"
                  >
                    {newStock}
                  </Typography>
                </Box>
              </FlexContainer>
            </Box>
          </FlexContainer>
        </Box>

        <Divider />

        <CardContent
          sx={{
            pt: 0,
            mt: 0,
            maxHeight: 'calc(100vh - 200px)',
            overflow: 'auto',
          }}
        >
          {!isEmpty(fields) ? (
            <Timeline
              sx={{
                margin: 0,
                pt: 0,
                pl: 3,
                pr: 0,
                [`& .${timelineItemClasses.root}:before`]: {
                  display: 'none',
                },
              }}
            >
              {fields?.map((field, index) => {
                const count = index + 1
                const isLast = count === fields?.length
                const isFirst = index === 0

                return (
                  <React.Fragment key={field.id}>
                    <BatchItem
                      {...{
                        count,
                        isLast,
                        isFirst,
                        theme,
                        conectorColor,
                        primaryCurrency,
                        formProps,
                        field,
                        index,
                        removeBatchItem,
                      }}
                    />
                  </React.Fragment>
                )
              })}

              <TimelineItem sx={{ mt: '-16px' }}>
                <TimelineSeparator></TimelineSeparator>

                <TimelineContent
                  component={'div'}
                  sx={{
                    padding: 0,
                  }}
                >
                  <FlexContainer justifyContent="space-start">
                    <Box
                      sx={{
                        width: '20px',
                        height: '1px',
                        border: `1px ${conectorStyle} ${conectorColor}`,
                      }}
                    ></Box>

                    <Button
                      variant="text"
                      startIcon={<Add fontSize="large" color="primary" />}
                      onClick={addNewBatch}
                      tabIndex={100}
                    >
                      Agregar lote
                    </Button>
                  </FlexContainer>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          ) : null}
        </CardContent>
        <CardActions sx={{ borderTop: `1px solid ${theme.palette.divider}` }}>
          {contextOfUse === 'NEW_PRODUCT' && isNewProduct ? (
            <FlexContainer sx={{ width: '100%' }}>
              <Button
                size="large"
                variant="outlined"
                fullWidth
                type={'button'}
                onClick={onPrevious}
                startIcon={<ArrowBackIosIcon />}
                sx={{ width: '30%', mr: 2 }}
              >
                Atras
              </Button>
              <Button
                startIcon={loading ? <CircularProgress size={20} /> : null}
                disabled={loading}
                size="large"
                variant="contained"
                fullWidth
                type={'submit'}
                endIcon={<Save />}
                // sx={{ width: '70%' }}
              >
                {loading ? 'Guardando...' : 'Recibir lotes'}
              </Button>
            </FlexContainer>
          ) : (
            <Button
              startIcon={loading ? <CircularProgress size={20} /> : null}
              disabled={loading}
              size="large"
              variant="contained"
              fullWidth
              type={'submit'}
            >
              {loading ? 'Guardando...' : 'Recibir lotes'}
            </Button>
          )}
        </CardActions>
      </form>

      {changePriceModal.visible ? (
        <ChangePriceDialog
          onClose={changePriceModal.close}
          {...{
            automaticPrice,
            setAutomaticPrice,
            currentPrice,
            newPrice,
            setNewPrice,
          }}
        />
      ) : null}

      <LocationsDialog
        visible={changeLocationModal.visible}
        onClose={changeLocationModal.close}
        {...{
          newLocation,
          setNewLocation,
        }}
      />
    </Card>
  )
}
