import { useState, useMemo, useEffect, useRef } from 'react'
import { floor } from 'lodash'
import { isEmpty } from 'lodash'
import { format } from 'date-fns'
import usePharmacy from 'hooks/usePharmacy'
import useParsers from 'hooks/useParsers'
import { numberIsValid } from 'utils/numbers/numberIsValid'
import { InvoiceStatements } from 'const/InvoiceStatements'
import { getInitialDate } from 'utils/dates/getInitialDate'
import { formatDate } from 'utils/formatDate'
import { getWeek } from 'utils/dates/getWeek'
import useCustomerSelector from 'hooks/useCustomerSelector'
import { toast } from 'sonner'

const default_exchange_rate = 36.6

export default function usePayInvoice({
  createNewInvoice,
  clearProductList,
  subtotal,
  productsCount,
}) {
  const [paymentMethod, setPaymentMethod] = useState('Efectivo')
  const { primaryCurrency, secondaryCurrency, selectedPharmacy } = usePharmacy()
  const [loading, setLoading] = useState(false)

  const [selectedCurrency, setSelectedCurrency] = useState('primary')

  const [typeOfInvoice, setTypeOfInvoice] = useState('Contado')

  const [applyDiscount, setApplyDiscount] = useState(false)
  const [viewCustomer, setViewCustomer] = useState(false)
  const [printReceipt, setPrintReceipt] = useState(false)
  const [customer, setCustomer] = useState({})
  const [discountType, setDiscountType] = useState('PERCENTAGE')

  const [invoiceDate, setInvoiceDate] = useState(new Date())
  const [clientPayment, setClientPayment] = useState('')
  const [exchangeRate, setExchangeRate] = useState(1)
  const [discountAmount, setDiscountAmount] = useState('')
  const [percentageDiscount, setPercentageDiscount] = useState('')

  const {
    state: customers,
    getData: getCustomers,
    getCustomerByName,
  } = useCustomerSelector()

  const { parseAmount } = useParsers()

  const submitTimeoutRef = useRef(null)
  const isSubmittingRef = useRef(false)

  const resetValues = () => {
    const _invoiceDate = new Date()

    setClientPayment('')
    setExchangeRate(1)
    setDiscountAmount('')
    setPercentageDiscount('')
    setApplyDiscount(false)
    setTypeOfInvoice('Contado')
    setViewCustomer(false)
    setPrintReceipt(false)
    setCustomer({})
    setDiscountType('PERCENTAGE')
    setPaymentMethod('Efectivo')
    setInvoiceDate(_invoiceDate)
    setSelectedCurrency('primary')
  }

  const cancelInvoice = () => {
    clearProductList()
    resetValues()
  }

  const getInvoiceStatus = () => {
    if (typeOfInvoice === 'Proforma') {
      return InvoiceStatements['PROFORM']?.value
    } else if (typeOfInvoice === 'Credito') {
      return InvoiceStatements['ACTIVE_CREDIT']?.value
    } else {
      return InvoiceStatements['PAID_IN_CASH']?.value
    }
  }

  const discountSummary = useMemo(() => {
    if (!applyDiscount)
      return {
        amount: 0,
        percentage: 0,
      }

    let amount

    let discountPercentage

    if (discountType === 'PERCENTAGE') {
      discountPercentage = parseFloat(percentageDiscount)

      const result = (subtotal * discountPercentage) / 100
      amount = floor(result, 2)
    } else {
      amount = parseFloat(discountAmount)

      const result = (discountAmount / subtotal) * 100

      discountPercentage = floor(result, 2)
    }

    if (!numberIsValid(amount))
      return {
        amount: 0,
        percentage: 0,
      }

    return {
      amount: amount,
      percentage: discountPercentage,
    }
  }, [
    subtotal,
    applyDiscount,
    discountType,
    discountAmount,
    percentageDiscount,
  ])

  const totalToPay = useMemo(() => {
    if (!numberIsValid(subtotal)) return 0

    if (!applyDiscount) return subtotal

    return subtotal - discountSummary?.amount
  }, [subtotal, discountSummary])

  const onSubmit = () => {
    if (isSubmittingRef.current) return

    if (submitTimeoutRef.current) {
      clearTimeout(submitTimeoutRef.current)
    }

    isSubmittingRef.current = true

    submitTimeoutRef.current = setTimeout(() => {
      isSubmittingRef.current = false
    }, 3000)

    try {
      if (productsCount === 0) {
        toast.error('Necesita agregar al menos un producto a la factura.')
      } else {
        if (loading) return
        setLoading(true)

        const isCashPayment =
          typeOfInvoice === 'Contado' && paymentMethod === 'Efectivo'

        const proforma = typeOfInvoice === 'Proforma'
        const pending = typeOfInvoice === 'Credito'

        if (pending && !customer?.name) {
          toast.error('Especificar un cliente para facturas de crédito')
        } else {
          const hasCustomer = !isEmpty(customer)

          const customer_name = hasCustomer ? customer?.name : ''
          const customer_ID = hasCustomer ? customer?.docID : ''

          const invoice_status = getInvoiceStatus()

          const invoiceDateObj = getInitialDate(invoiceDate)

          const todayString = format(new Date(), 'yyyy-MM-dd')
          const isToday = todayString === invoiceDateObj?.dateString

          const rawDate = isToday
            ? new Date()
            : new Date(invoiceDateObj?.dateString)

          const hour_only = format(invoiceDateObj?.timestamp, 'hh')
          const am_pm = format(invoiceDateObj?.timestamp, 'aaa')

          const time_string = `${hour_only}:00 ${am_pm}`

          const timestamp = invoiceDateObj?.timestamp

          const creation_dates = {
            time: timestamp,
            date: invoiceDateObj?.dateString,
            created_date: rawDate,
            day_of_week: formatDate(timestamp, 'EEEE'),
            week: getWeek(timestamp),
            month_and_year: formatDate(timestamp, 'MMM yyyy'),
            month_only: formatDate(timestamp, 'MMMM'),
            year: invoiceDateObj?.year,
            time_string,
          }

          const clientPaymentValidated = numberIsValid(Number(clientPayment))
            ? Number(clientPayment)
            : totalToPay

          const payment_info = {
            ...creation_dates,
            total_paid: totalToPay,
            payment_method: paymentMethod,
            currency: isCashPayment ? selectedCurrency : '',
            client_payment: isCashPayment ? clientPaymentValidated : totalToPay,
            exchange_rate_result: isCashPayment
              ? Number(customerPayWith)
              : totalToPay,
            cambio: isCashPayment ? Number(changeToReturn) : 0,
            paid: typeOfInvoice === 'Contado',
            proforma: proforma,
            revoked: false,
            pending: pending,
            type_of_invoice: typeOfInvoice,
            discount_amount: numberIsValid(discountAmount)
              ? discountSummary?.amount
              : 0,
            percentage_discount: numberIsValid(discountAmount)
              ? discountSummary?.percentage
              : 0,
            customer_name: Boolean(customer_name) ? customer_name : '',
            customer_ID: Boolean(customer_ID) ? customer_ID : '',
            invoice_status,
            balance: totalToPay,
          }

          createNewInvoice({
            payment_info,
            resetPaymentForm: () => {
              resetValues()
            },
          })
        }
      }
    } catch (error) {
      console.error(error)
      console.error('Errror al guardar factura')
    } finally {
      setLoading(false)
    }
  }

  const currencies = useMemo(() => {
    const primary = {
      ...primaryCurrency,
      label: primaryCurrency.label,
      value: primaryCurrency.label,
      type: 'primary',
    }

    const secondary = {
      ...secondaryCurrency,
      label: secondaryCurrency.label,
      value: secondaryCurrency.label,
      type: 'secondary',
    }

    setSelectedCurrency('primary')

    return [primary, secondary]
  }, [primaryCurrency, secondaryCurrency])

  const toggleApplyDiscount = event => {
    setApplyDiscount(event.target.checked)
  }

  const handleViewCustomer = event => {
    setViewCustomer(event.target.checked)
  }

  const handlePrintReceipt = event => {
    setPrintReceipt(event.target.checked)
  }

  const customerPayWith = useMemo(() => {
    if (selectedCurrency === 'primary') return Number(clientPayment)

    const result = Number(clientPayment) * Number(exchangeRate)

    return result
  }, [selectedCurrency, exchangeRate, clientPayment])

  const changeToReturn = useMemo(() => {
    const result = customerPayWith - totalToPay

    if (result < 1) return 0

    return result
  }, [customerPayWith, totalToPay])

  useEffect(() => {
    const country = selectedPharmacy?.pharmacy_country
    const isNicaragua = country === 'Nicaragua 🇳🇮'
    const isPrimaryCurrency = selectedCurrency === 'primary'

    if (isPrimaryCurrency) {
      setExchangeRate(1)
    } else if (!isPrimaryCurrency && isNicaragua) {
      setExchangeRate(default_exchange_rate)
    }
  }, [selectedCurrency, selectedPharmacy])

  return {
    onSubmit,
    selectedCurrency,
    setSelectedCurrency,
    paymentMethod,
    setPaymentMethod,
    customerPayWith,
    changeToReturn,
    parseAmount,
    currencies,
    applyDiscount,
    toggleApplyDiscount,
    primaryCurrency,
    totalToPay,
    discountType,
    setDiscountType,
    discountSummary,
    customers,
    getCustomers,
    typeOfInvoice,
    setTypeOfInvoice,
    invoiceDate,
    setInvoiceDate,
    viewCustomer,
    handleViewCustomer,
    printReceipt,
    handlePrintReceipt,
    secondaryCurrency,
    customer,
    setCustomer,
    clientPayment,
    setClientPayment,
    exchangeRate,
    setExchangeRate,
    discountAmount,
    setDiscountAmount,
    percentageDiscount,
    setPercentageDiscount,
    cancelInvoice,
    loading,
  }
}
