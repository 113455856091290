import React from 'react'
import { useEffect, useState } from 'react'
import {
  collection,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { db } from 'config/firebase'
import { snakeCase, isEmpty } from 'lodash'
import { formatDate } from 'utils/formatDate'
import usePharmacy from 'hooks/usePharmacy'
import { getProductRef } from 'services/getProductRef'

export default function useProductsSold({ date }) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: [],
  })

  const { selectedPharmacy } = usePharmacy()

  const getProductById = name => {
    if (isEmpty(state?.data)) return {}

    const result = state?.data?.find(
      customer => snakeCase(customer?.name) === snakeCase(name)
    )

    if (isEmpty(result)) return {}

    return result
  }

  const getProductData = async baseData => {
    const productRef = getProductRef(baseData.product_ID)

    const snap = await getDoc(productRef)

    const product = snap.data()

    return { ...baseData, ...product }
  }

  const getData = async querySnapshot => {
    try {
      if (querySnapshot?.empty) {
        setState({
          error: false,
          loading: false,
          data: [],
        })
      } else {
        const _data = []

        querySnapshot.forEach(documentSnapshot => {
          const response = documentSnapshot.data()

          const _item = {
            docID: documentSnapshot.id,
            ...response,
          }

          _data.push(_item)
        })

        const productsPromises = _data.map(product => getProductData(product))

        const products = await Promise.all(productsPromises)

        setState({
          error: false,
          loading: false,
          data: products,
        })
      }
    } catch (error) {
      console.error('Error al obtener la lista de productos vendidos')
      console.error(error)
    }
  }

  //   useEffect(() => {
  //     getData()
  //   }, [selectedPharmacy?.pharmacy_ID])

  useEffect(() => {
    try {
      const pharmacy_ID = selectedPharmacy?.pharmacy_ID

      if (!pharmacy_ID) return

      const dateString = formatDate(date, 'yyyy-MM-dd')

      const q = query(
        collection(db, 'products_sold_per_day'),
        where('pharmacy_ID', '==', pharmacy_ID),
        where('date', '==', dateString),
        orderBy('count', 'desc')
      )

      const subscriber = onSnapshot(q, getData, error => {
        console.error(error)
      })

      return () => subscriber()
    } catch (e) {
      console.error(e)
    }
  }, [selectedPharmacy?.pharmacy_ID, date])

  return { state, getData, getCustomerByName: getProductById }
}
