import React, { useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { isNumber } from 'lodash'
import PlusIcon from '@mui/icons-material/Add'
import MinusIcon from '@mui/icons-material/Remove'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton, TextField } from '@mui/material'
import { numberIsValid } from 'utils/numbers/numberIsValid'
import styled from '@emotion/styled'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const EnterKey = 13

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

const CounterButton = props => {
  const {
    mb = 0,
    mt = 0,
    ml = 0,
    mr = 0,
    value = 1,
    min = 1,
    max,
    deleteAction = true,
    onChangeValue = () => {},
    onDelete = () => {},
    iconSize = 20,
    borderRadius = 6,
    setEditting = () => {},
    tabIndex = 0,
  } = props

  const buttonSize = 'small'

  const [count, setCount] = useState(value)
  const inputRef = useRef(null)

  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const theme = useTheme()

  const iconBgColor = theme.palette.neutral[800]
  const disabledBgColor = 'transparent'
  const decreaseIconTextColor =
    value <= min ? theme.palette.action.disabled : '#fff'
  const increaseIconTextColor =
    value >= max ? theme.palette.action.disabled : '#fff'

  const backgroundColor = theme.palette.action.hover

  const onChange = _qty => {
    const value = Number(_qty)

    let newValue

    if (isNumber(min) && value < min) {
      newValue = min
    } else if (isNumber(max) && value > max) {
      newValue = max
    } else {
      newValue = value
    }

    setCount(newValue)
    onChangeValue(newValue)
  }

  const increaseCount = () => {
    setCount(count => {
      const newValue = count + 1

      if (isNumber(max) && newValue > max) {
        onChangeValue(max)

        return max
      } else {
        onChangeValue(newValue)

        return newValue
      }
    })
  }

  const decreaseCount = () => {
    setCount(count => {
      const newValue = count - 1

      if (isNumber(min) && newValue < min) {
        onChangeValue(min)

        return min
      } else {
        onChangeValue(newValue)

        return newValue
      }
    })
  }

  const iconButtonStyles = {
    // backgroundColor: theme.palette.action.hover,
    backgroundColor: theme.palette.neutral[600],
    color: theme.palette.neutral['white'],
    borderRadius: theme.shape.borderRadius,

    '&:hover': {
      backgroundColor: theme.palette.neutral['black'],
      color: theme.palette.neutral['white'],
    },
  }

  const buttonTabIndex = 100

  const saveQty = () => {
    try {
      const value = Number(count)

      let newValue

      if (isNumber(min) && Number(value) < min) {
        newValue = min
      } else if (isNumber(max) && Number(value) > max) {
        newValue = max
      } else {
        newValue = Number(value)
      }

      onChange(newValue)
    } catch (e) {
      console.log(e)
    } finally {
      setEditting(false)
    }
  }

  return (
    <Box
      sx={{
        mt,
        mb,
        mr,
        ml,
        width: '150px',
      }}
    >
      <Box display={'flex'} alignItems={'center'}>
        {deleteAction && Number(count) <= min ? (
          <IconButton
            tabIndex={buttonTabIndex}
            size={buttonSize}
            sx={{
              backgroundColor: theme.palette.neutral[600],
              color: theme.palette.neutral['white'],
              '&:hover': {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.neutral['white'],
              },
            }}
            onClick={onDelete}
          >
            <DeleteIcon size={iconSize} color={'#fff'} />
          </IconButton>
        ) : (
          <IconButton
            tabIndex={buttonTabIndex}
            size={buttonSize}
            onClick={decreaseCount}
            sx={{
              ...iconButtonStyles,
            }}
          >
            <MinusIcon size={iconSize} color={decreaseIconTextColor} />
          </IconButton>
        )}
        <LightTooltip
          title="Presiona la tecla Enter para guardar"
          placement="top"
          disableHoverListener
        >
          <TextField
            autoComplete="off"
            inputRef={inputRef}
            size="small"
            hiddenLabel
            variant="filled"
            value={String(count)}
            onChange={event => {
              const value = event.target.value

              setCount(Number(value))
            }}
            onFocus={() => {
              setEditting(true)
              inputRef?.current?.select()
            }}
            onKeyDown={event => {
              if (event?.keyCode === EnterKey) {
                if (Number(value) === Number(count)) return

                saveQty()
              }
            }}
            onBlur={() => {
              if (Number(value) === Number(count)) return

              saveQty()
            }}
            sx={{
              mr: 1,
              ml: 1,
              '& input': {
                fontSize: 16,
                textAlign: 'center',
                fontWeight: 'bold',
              },
            }}
            type="number"
            inputProps={{
              min: numberIsValid(min) ? min : 1,
              tabIndex: 0,
            }}
          />
        </LightTooltip>

        <IconButton
          tabIndex={buttonTabIndex}
          sx={{
            ...iconButtonStyles,
          }}
          size={buttonSize}
          onClick={increaseCount}
        >
          <PlusIcon size={iconSize} color={increaseIconTextColor} />
        </IconButton>
      </Box>
    </Box>
  )
}

export default CounterButton
