import React, { useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import ProductMenu from '../ProductMenu'

const getActiveProducts = ({ data, archived = false }) => {
  const array = Object.values(data)

  const result = array.filter(hit => hit?.archived === archived)

  return result
}

export default function Table({
  editProductModal,
  rows,
  columns,
  setHitsPerPage,
  handleChangePage,
  currentRefinement,
  nbHits,
  hitsPerPage,
  sortModel,
  showArchived = false,
  productDetail,
  archiveProduct,
  unarchiveProduct,
}) {
  const [menu, setMenu] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const openMenu = event => {
    setAnchorEl(event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <DataGrid
        // hideFooter={true}
        disableColumnFilter
        slots={{ toolbar: GridToolbar }}
        localeText={{
          columnsManagementShowHideAllText: 'Mostrar todo/Ocultar todo',
          columnsManagementReset: 'Restablecer',
          columnsManagementSearchTitle: 'Buscar columna',
          toolbarColumns: 'Columnas',
          toolbarColumnsLabel: 'Columnas',
          toolbarExportLabel: 'Exportar',
          toolbarExport: 'Exportar',
          toolbarExportPrint: 'Imprimir',
          toolbarExportCSV: 'Descargar CSV',
          toolbarDensity: 'Tamaño',
          toolbarDensityLabel: 'Tamaño',
          toolbarDensityCompact: 'Pequeño',
          toolbarDensityStandard: 'Mediano',
          toolbarDensityComfortable: 'Grande',
          // footerRowSelected: 'Selecciones para',
        }}
        rows={getActiveProducts({ data: rows, archived: showArchived })}
        columns={columns}
        autoHeight
        rowHeight={70}
        pageSizeOptions={[15, 50, 100]}
        paginationMode="server"
        paginationModel={{
          page: currentRefinement,
          pageSize: hitsPerPage,
        }}
        rowCount={nbHits}
        onPaginationModelChange={payload => {
          const pageSize = payload?.pageSize

          if (pageSize !== hitsPerPage) {
            setHitsPerPage(payload?.pageSize)
            handleChangePage(1)
          } else {
            handleChangePage(payload?.page)
          }
        }}
        checkboxSelection
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: {
              sold_units: false,
              product_name_raw: false,
              pharmaceutical_form: false,
              laboratory: false,
              min_stock: false,
              max_stock: false,
              stock_raw: false,
              expiration_date_timestamp: false,
              current_price_number: false,
            },
          },
        }}
        sx={{
          border: 'none',
        }}
        sortModel={sortModel}
        onRowDoubleClick={(response, event) => {
          setSelectedRow(response?.row)
          openMenu(event)
          setMenu({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          })
        }}
      />

      {open && (
        <ProductMenu
          anchorEl={anchorEl}
          open={open}
          menu={menu}
          handleClose={closeMenu}
          data={selectedRow}
          productDetail={productDetail}
          editProductModal={editProductModal}
          archiveProduct={archiveProduct}
          unarchiveProduct={unarchiveProduct}
        />
      )}
    </Box>
  )
}
