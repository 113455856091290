import React from 'react'
import useListOfInvoices from './useListOfInvoices'
import { DataGrid } from '@mui/x-data-grid/DataGrid'
import { Box } from '@mui/material'
import useModal from 'hooks/useModal'
import SalesDetail from 'features/sales/components/SalesDetail'

export default function ListOfInvoices({ date }) {
  const { columns, state } = useListOfInvoices({ date })
  const salesDetail = useModal()

  return (
    <Box sx={{ height: 'calc(100vh - 400px)' }}>
      <DataGrid
        disableRowSelectionOnClick
        columns={columns}
        rows={state.data}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        onRowClick={({ row }) => {
          salesDetail.open({ data: row })
        }}
      />

      {salesDetail.visible && (
        <SalesDetail
          data={salesDetail.data}
          visible={salesDetail.visible}
          onClose={salesDetail.close}
        />
      )}
    </Box>
  )
}
