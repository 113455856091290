import { numberIsValid } from 'utils/numbers/numberIsValid'
import { isEmpty, floor, sumBy } from 'lodash'
import { differenceInDays, startOfMonth, subMonths } from 'date-fns'
import { formatDate } from 'utils/formatDate'
import { db } from 'config/firebase'
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore'

export const getAverageSalesPerDay = async product => {
  const currentMonth = startOfMonth(new Date())
  const currentDate = new Date()

  const lastMonth = startOfMonth(subMonths(currentMonth, 1))
  const beforeLastMonth = startOfMonth(subMonths(currentMonth, 2))

  const currentMonthString = formatDate(currentMonth, 'yyyy-MM-dd')
  const lastMonthString = formatDate(lastMonth, 'yyyy-MM-dd')
  const beforeLastMonthString = formatDate(beforeLastMonth, 'yyyy-MM-dd')

  const daysElapsedUntilToday = differenceInDays(new Date(), beforeLastMonth)

  const q = query(
    collection(db, 'monthly_sales_by_product'),
    where('pharmacy_ID', '==', product?.pharmacy_ID),
    where('product_ID', '==', product?.product_ID),
    where('month_value', 'in', [
      beforeLastMonthString,
      lastMonthString,
      currentMonthString,
    ]),
    orderBy('month_value', 'desc'),
    limit(3)
  )

  const querySnapshot = await getDocs(q)

  const summaries = []

  querySnapshot.forEach(documentSnapshot => {
    if (!documentSnapshot?.exists) return

    const data = documentSnapshot.data()

    const result = {
      ...data,
      doc_ID: documentSnapshot.id,
    }

    summaries.push(result)
  })

  let average
  let totalSalesLabel
  let totalSalesCount

  if (!isEmpty(summaries)) {
    const totalSales = sumBy(summaries, 'total_sales')

    average = totalSales / daysElapsedUntilToday

    totalSalesCount = totalSales

    totalSalesLabel = `(${formatDate(
      beforeLastMonth,
      'd MMM yy'
    )} - ${formatDate(currentDate, 'd MMM yy')})`
  } else {
    average = 0
    totalSalesLabel = ''
    totalSalesCount = 0
  }

  const averageSalesPerDay = numberIsValid(average) ? floor(average, 2) : 0

  return { averageSalesPerDay, totalSalesLabel, totalSalesCount }
}
