import { isEmpty } from 'lodash'
import { cropText } from 'utils/cropText'
import { formatDate } from 'utils/formatDate'

const getDetails = ({ invoice, parseAmount }) => {
  if (isEmpty(invoice?.details)) return ''

  let products = ''

  invoice?.details?.forEach(product => {
    const name = `${cropText(product?.product_name)} ${cropText(
      product?.pharmaceutical_form
    )} ${cropText(product?.laboratory)}`

    const product_name_line = `${openB}* ${name}${closeB}`

    const product_qty_line = `${product?.qty} und X ${parseAmount({
      value: product?.sale_price,
      showCode: false,
    })}`

    const product_price_line = `${openB}${parseAmount({
      value: product?.total,
      showCode: false,
    })}${closeB}`

    products =
      products +
      `${left}${product_name_line}\n` +
      `${left}    ${product_qty_line}= ${product_price_line}\n\n`
  })

  return products
}

const partialCut = '\x1D\x56\x42\x00'

const address = `De la entrada a San Ramón 300 vrs al este. Tisma`

const small = '\x1B\x21\x00'
const big = '\x1B\x21\x30'
const big2 = '\x1D\x21\x01'

const openB = '\x1B\x45\x01'
const closeB = '\x1B\x45\x00'

//Align
const right = '\x1B\x61\x02'
const center = '\x1B\x61\x01'
const left = '\x1B\x61\x00'

const configEncoding = `\x1B\x74\x02` // Configurar a CP850

const openDrawer = `
  \x1B\x40
  \x1B\x70\x00\x19\xFA
`

export const getReceiptTemplate = ({
  parseAmount = () => {},
  invoice,
  pharmacy,
  transacctionType,
}) => {
  const details = getDetails({ invoice, parseAmount })
  const created_date = formatDate(invoice?.time, 'eee, dd MMM yyyy')
  const time = formatDate(invoice?.time, 'hh:mm a')
  const invoice_amount = parseAmount({
    value: invoice?.total_paid,
  })

  const client_payment = invoice?.client_payment

  const cambio = invoice?.cambio

  const isProform = transacctionType === 'Proforma'

  const isAcredit = invoice?.type_of_invoice === 'Credito'

  const customer_name = invoice?.customer_name || ''

  const footerLine1 = isProform
    ? ''
    : `${right}${openB}Paga con: ${right}${client_payment}${closeB}\n`
  const footerLine2 = isProform
    ? ''
    : `${right}${openB}Cambio: ${right}${cambio}${closeB}\n`

  const footerMessage = isProform
    ? 'Esta proforma es válida por 20 días.'
    : 'Gracias por su compra'

  return (
    `${center}${big2}${openB}${pharmacy?.pharmacy_name}${closeB}${small}\n` +
    `${center}Telefono: +505 ${pharmacy?.pharmacy_phone}\n` +
    `${center}${address}\n` +
    `${small}\n` +
    `${small}\n` +
    // `${left}${small}${openB}Transaccion #: ${closeB}${invoice.transaction_number}\n` +
    `${left}${small}${openB}Vendedor: ${closeB}${invoice.seller}\n` +
    `${left}${small}${openB}Fecha: ${closeB}${created_date}\n` +
    `${left}${small}${openB}Hora: ${closeB}${time}\n` +
    `${small}${openB}Cliente: ${closeB}${customer_name}\n` +
    `${small}\n` +
    `${left}------------------------------------------------\n` +
    `${center}${big}${
      isAcredit ? 'Factura de crédito' : transacctionType
    }${small}\n` +
    `${left}------------------------------------------------\n` +
    details +
    `${left}------------------------------------------------\n` +
    `${small}${openB}Total: ${right}${invoice_amount}${closeB}\n` +
    footerLine1 +
    footerLine2 +
    `${small}\n` +
    `${small}\n` +
    `${center}${footerMessage}\n` +
    openDrawer +
    partialCut
  )
}
