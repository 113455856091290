const env = process.env

const API_KEY = env.REACT_APP_PRINT_NODE_API_KEY // Reemplázalo con tu API Key de PrintNode

export const getPrinters = async () => {
  try {
    const response = await fetch('https://api.printnode.com/printers', {
      method: 'GET',
      headers: {
        Authorization: 'Basic ' + btoa(API_KEY + ':'),
      },
    })

    if (!response.ok) throw new Error('Error obteniendo impresoras')

    const printers = await response.json()
    return printers
  } catch (error) {
    console.error('Error:', error)
    return []
  }
}
