import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from 'config/firebase'
import { isEmpty } from 'lodash'

export const getLocationsByPharmacy = async pharmacy_ID => {
  try {
    const q = query(
      collection(db, 'locations'),
      where('pharmacy_ID', '==', pharmacy_ID)
    )

    const querySnapshot = await getDocs(q)

    if (querySnapshot?.empty) return {}

    let _data = {}

    querySnapshot.forEach(documentSnapshot => {
      const response = documentSnapshot.data()
      const _item = {
        docID: documentSnapshot.id,
        ...response,
        label: response?.location,
        value: response?.location,
      }

      _data[response?.location] = _item
    })

    return _data
  } catch (error) {
    console.error('Error al obtener la lista de Locations')
    console.error(error)
  }
}
