import { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { isEmpty, uniqueId } from 'lodash'
import { parseGeneratedCodes } from 'utils/parseGeneratedCodes'
import usePharmacy from 'hooks/usePharmacy'
import { searchProductInDB } from 'services/searchProductInDB'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { auth, db } from 'config/firebase'
import { toast } from 'sonner'
import { INITIAL_STATS } from 'services/inventory/getProductStatistics'

const INITIAL_VALUE = {
  product_code: '',
  generated_product_code: '',
  product_name: '',
  laboratory: '',
  pharmaceutical_form: '',
  manual_min_stock: '',
  manual_max_stock: '',
  location: '',
}

export default function useCreateProduct({
  onSuccess = () => {},
  alreadyExists = false,
} = {}) {
  const [hasBarcode, setHasBarcode] = useState(true)
  const [prescriptionRequired, setPrescriptionRequired] = useState(false)
  const [product, setProduct] = useState({})
  const [activeStep, setActiveStep] = useState(0)
  const [isExistingProduct, setIsExistingProduct] = useState(false)

  const submitTimeoutRef = useRef(null)
  const isSubmittingRef = useRef(false)

  const { selectedPharmacy } = usePharmacy()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: INITIAL_VALUE,
  })

  const resetNewProduct = () => {
    setActiveStep(0)
    setProduct({})
    reset(INITIAL_VALUE)
    setPrescriptionRequired(false)
    setHasBarcode(true)
    setIsExistingProduct(false)
  }

  const toggleHasBarcode = event => {
    setHasBarcode(event.target.checked)
  }

  const togglePrescriptionRequired = event => {
    setPrescriptionRequired(event.target.checked)
  }

  const updateConsecutiveCodes = async code => {
    try {
      const pharmacyDoc = selectedPharmacy?.pharmacy_ID

      const doc_ID = `code_${pharmacyDoc}`

      const currentCode = Number(code)

      await setDoc(doc(db, 'automatic_product_codes', doc_ID), {
        code: currentCode,
      })
    } catch (err) {
      console.error('Error al guardar el codigo consecutivo')

      console.error(err)
    }
  }

  const codeAlreadyExists = async event => {
    const code = event.target.value

    if (!code) return

    const product = await searchProductInDB({
      code,
      selectedPharmacy,
    })

    const exists = product?.exists

    if (exists) {
      setIsExistingProduct(true)

      const productData = product.data()
      const product_ID = product.id

      setProduct({
        ...productData,
        stock: productData?.stock,
        current_price: productData?.current_price,
        product_code: productData?.product_code,
        product_ID: product_ID,
        pharmaceutical_form: productData?.pharmaceutical_form,
        laboratory: productData?.laboratory,
        location: productData?.location,
        selectors: {
          pharmaceutical_form: productData?.pharmaceutical_form,
          laboratory: productData?.laboratory,
          location: productData?.location,
        },
      })
      setActiveStep(1)
    }
  }

  const createNewProduct = async ({
    newPrice = 0,
    newLocation = '',
    saveBatches = () => {},
  }) => {
    if (isSubmittingRef.current) return

    if (submitTimeoutRef.current) {
      clearTimeout(submitTimeoutRef.current)
    }

    isSubmittingRef.current = true

    submitTimeoutRef.current = setTimeout(() => {
      isSubmittingRef.current = false
    }, 3000)

    try {
      if (isEmpty(product)) return

      const product_ID = product?.product_ID

      const user = auth?.currentUser

      const user_ID = user?.uid

      const manualMinStock = Number(product.manual_min_stock)
      const manualMaxStock = Number(product.manual_max_stock)

      const adicionalInfo = {
        active_principle: '',
        category: '',
        description: '',
        created_by: user_ID,
        prescription_required: prescriptionRequired,
        low_stock: false,
        sold_units: 0,
        last_action: 'Agregado recientemente',
        modified_date: new Date(),
        stock_status: 'HEALTHY_STOCK',
      }

      const newProduct = {
        pharmacy_ID: selectedPharmacy?.pharmacy_ID,
        has_a_barcode: hasBarcode,
        product_code: product?.product_code,
        product_name: product?.product_name,
        stock: 0,
        current_price: newPrice,
        created_date: new Date(),
        sourcing_time: 2,
        average_sales_per_day: 0,
        last_sale: '',
        cost: 0,
        supplier_name: '',
        supplier_ID: '',
        laboratory: product?.laboratory,
        pharmaceutical_form: product?.pharmaceutical_form,
        manual_min_stock: manualMinStock,
        manual_max_stock: manualMaxStock,
        location: newLocation,
        ...adicionalInfo,
        average_per_day: 0,
        average_per_week: 0,
        average_per_month: 0,
        sold_last_3months: 0,
        sold_out_in: 0,
        sold_outin_status: '',
        stats: INITIAL_STATS,
        archived: false,

        //nuevas propiedades
        notify_when_sold: false,
        archive_when_exhausted: false,
      }

      await setDoc(doc(db, 'products', product_ID), newProduct)

      if (!hasBarcode) {
        updateConsecutiveCodes(product?.product_code)
      }

      saveBatches()
    } catch (err) {
      console.error('Error al guardar producto')
      console.error(err)
    }
  }

  const generateCode = async () => {
    const pharmacyDoc = selectedPharmacy?.pharmacy_ID

    const doc_ID = `code_${pharmacyDoc}`

    const prevCode = await getDoc(doc(db, 'automatic_product_codes', doc_ID))

    if (prevCode?.exists) {
      const newCode = prevCode.data()?.code + 1

      const _code = parseGeneratedCodes(newCode)

      return _code
    } else {
      const _code = parseGeneratedCodes(1)

      return _code
    }
  }

  const getFormProps = ({ name, rules = { required: true } }) => {
    return {
      name,
      control,
      errors,
      rules,
    }
  }

  const prevStep = () => {
    setActiveStep(0)
  }

  const nextStep = () => {
    setActiveStep(1)
  }

  const onNextStep = productData => {
    const productCode = hasBarcode
      ? watch('product_code')
      : watch('generated_product_code')

    if (isEmpty(productData?.pharmaceutical_form))
      return toast.error('Seleccione una forma farmacéutica para este producto')

    if (isEmpty(productData?.laboratory))
      return toast.error('Seleccione un laboratorio para este producto')

    if (isEmpty(productData?.location))
      return toast.error('Seleccione una ubicación para este producto')

    const pharmacyDoc = selectedPharmacy?.pharmacy_ID

    const product_ID = `${pharmacyDoc}_${productCode}_${uniqueId()}`

    setProduct({
      ...productData,
      stock: 0,
      current_price: 0,
      product_code: productCode,
      product_ID,
      pharmaceutical_form: productData?.pharmaceutical_form?.value,
      laboratory: productData?.laboratory?.value,
      location: productData?.location?.value,
      selectors: {
        pharmaceutical_form: productData?.pharmaceutical_form,
        laboratory: productData?.laboratory,
        location: productData?.location,
      },
    })

    nextStep()
  }

  const onPreviousStep = () => {
    setValue('product_code', product?.product_code)
    setValue('generated_product_code', product?.generated_product_code)
    setValue('product_name', product?.product_name)
    setValue('manual_min_stock', product?.manual_min_stock)
    setValue('manual_max_stock', product?.manual_max_stock)
    setValue('laboratory', product?.selectors?.laboratory)
    setValue('pharmaceutical_form', product?.selectors?.pharmaceutical_form)
    setValue('location', product?.selectors?.location)

    prevStep()
  }

  useEffect(() => {
    if (hasBarcode) return

    generateCode()
      .then(code => {
        setValue('generated_product_code', code)
      })
      .catch(error => {
        console.error('No se pudo generar el codigo personalizado')

        console.error(error)
      })
  }, [hasBarcode])

  return {
    errors,
    getFormProps,
    hasBarcode,
    toggleHasBarcode,
    togglePrescriptionRequired,
    prescriptionRequired,
    product,
    setProduct,
    handleSubmit,
    watch,
    setValue,
    createNewProduct,
    onNextStep,
    activeStep,
    prevStep,
    setActiveStep,
    onPreviousStep,
    resetNewProduct,
    codeAlreadyExists,
    isExistingProduct,
  }
}
