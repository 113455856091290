import React, { useState } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField,
  Typography,
  Select,
  Card,
  useTheme,
  CardContent,
  Button,
  Tabs,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import MlIcon from '@mui/icons-material/Vaccines'
import DropIcon from '@mui/icons-material/WaterDrop'
import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid'
import randomKey from 'utils/randomKey'
import FlexContainer from 'components/FlexContainer'
import { floor, isEmpty, lowerCase, uniqueId } from 'lodash'
import TextGroup from 'components/TextGroup'
import { getUserById } from 'utils/getUserById'
import usePharmacy from 'hooks/usePharmacy'
import { cropText } from 'utils/cropText'
import useParsers from 'hooks/useParsers'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined'
import ReturnCustomerIcon2 from '@mui/icons-material/MoneyOffCsredOutlined'
import ReturnCustomerIcon from '@mui/icons-material/ReplyOutlined'
import { InvoiceStatements } from 'const/InvoiceStatements'
import { formatDate } from 'utils/formatDate'

export default function SalesDetail({
  visible = true,
  onClose = () => {},
  data,
}) {
  const theme = useTheme()
  const { selectedPharmacy, users } = usePharmacy()
  const { parseAmount } = useParsers()

  const seller = getUserById({ users, user_ID: data?.created_by })
  const isAcredit = data?.type_of_invoice === 'Credito'
  const isProform = data?.type_of_invoice === 'Proforma'

  const type = data?.type_of_invoice === 'Proforma' ? 'Proforma' : 'Factura'

  const title = `${type} #${data?.transaction_number}`

  const status = InvoiceStatements[data?.invoice_status]

  return (
    <Dialog
      keepMounted
      open={visible}
      onClose={onClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ mb: 1 }}>
          <FlexContainer justifyContent="space-between">
            {data?.apply_for_return ? (
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                endIcon={<ReturnCustomerIcon />}
                sx={{ mr: 0.5 }}
              >
                Aplicar devolución
              </Button>
            ) : null}

            <Button
              fullWidth
              variant="outlined"
              color="primary"
              endIcon={<LocalPrintshopOutlinedIcon />}
              sx={{ ml: 0.5 }}
            >
              Reimprimir recibo
            </Button>
          </FlexContainer>
        </Box>
        {isAcredit && data?.balance > 0 ? (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            endIcon={<PaymentsOutlinedIcon />}
            sx={{ mt: 0.5, mb: 2 }}
          >
            Recibir pago
          </Button>
        ) : null}

        {/*Descripción*/}

        <Box sx={{ mb: 2 }}>
          <Typography sx={{ fontWeight: 600 }} variant="subtitle1">
            Descripción
          </Typography>
          {isProform ? (
            <TextGroup
              mb={0}
              label="Tipo de venta"
              value={`Venta de ${data?.type_of_invoice?.toLowerCase()}`}
            />
          ) : null}

          <TextGroup
            mb={0}
            label="Fecha"
            value={`${formatDate(data?.time, 'eee, d MMM hh:mm a')}`}
          />
          <TextGroup mb={0} label="Vendedor" value={seller?.user_name} />
          <TextGroup mb={0} label="Cliente" value={data?.customer_name} />

          <TextGroup
            mb={0}
            label="Método de pago"
            value={data?.payment_method}
          />
          <TextGroup mb={0} label="Estado" value={status?.label} />
        </Box>

        {/*Resumen*/}
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ fontWeight: 600 }} variant="subtitle1">
            Resumen
          </Typography>
          {data?.payment_method === 'Efectivo' ? (
            <>
              <TextGroup
                mb={0}
                label="Subtotal"
                value={parseAmount({
                  value: data?.invoice_amount,
                  showCode: true,
                })}
              />
              <TextGroup
                mb={0}
                label={`Descuento ${
                  data?.percentage_discount > 0
                    ? `${data?.percentage_discount}%`
                    : ''
                }`}
                value={parseAmount({
                  value: data?.discount_amount,
                  showCode: true,
                })}
              />

              {/* <Divider sx={{ mt: 1, mb: 1 }} />*/}

              <TextGroup
                mb={0}
                label="Total"
                value={parseAmount({ value: data?.total_paid, showCode: true })}
              />
              <TextGroup
                mb={0}
                label="Pago con"
                value={parseAmount({
                  value: data?.client_payment,
                  showCode: true,
                })}
              />
              <TextGroup
                mb={0}
                label="Cambio"
                value={parseAmount({ value: data?.cambio, showCode: true })}
              />
            </>
          ) : (
            <>
              <TextGroup
                mb={0}
                label="Subtotal"
                value={parseAmount({
                  value: data?.invoice_amount,
                  showCode: true,
                })}
              />
              <TextGroup
                mb={0}
                label={`Descuento ${
                  data?.percentage_discount > 0
                    ? `${data?.percentage_discount}%`
                    : ''
                }`}
                value={parseAmount({
                  value: data?.discount_amount,
                  showCode: true,
                })}
              />
              <Divider sx={{ mt: 1, mb: 1 }} />

              <TextGroup
                mb={0}
                label="Total"
                value={parseAmount({ value: data?.total_paid, showCode: true })}
              />
            </>
          )}

          {/* <Divider sx={{ mt: 1, mb: 1 }} /> */}
        </Box>

        {/* <Divider sx={{ mt: 1, mb: 1 }} /> */}

        {/*Prductos*/}
        <Box>
          <FlexContainer justifyContent="space-between">
            <Typography sx={{ mt: 2, fontWeight: 600 }} variant="subtitle1">
              {`Productos (${data?.details?.length})`}
            </Typography>
            <Typography sx={{ mt: 2, fontWeight: 600 }} variant="subtitle1">
              {parseAmount({ value: data?.total_paid, showCode: true })}
            </Typography>
          </FlexContainer>
          <List
            sx={theme => ({
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
              //   backgroundColor: theme.palette.action.hover,
              padding: 0,
              margin: 0,
            })}
          >
            {data?.details?.map(detail => {
              const title = `${cropText(detail?.name)} ${cropText(
                detail?.pharmaceutical_form
              )} ${cropText(detail?.laboratory)}`

              const description = `${detail?.qty} und X ${parseAmount({
                value: detail?.sale_price,
                showCode: false,
              })} = ${parseAmount({ value: detail?.total, showCode: false })}`

              return (
                <ListItem
                  key={uniqueId()}
                  sx={{
                    paddingTop: '0 !important',
                    paddingBottom: '0 !important',
                  }}
                >
                  <ListItemText primary={title} secondary={description} />
                </ListItem>
              )
            })}
          </List>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
