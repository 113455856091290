import iconv from 'iconv-lite'

const env = process.env

const API_KEY = env.REACT_APP_PRINT_NODE_API_KEY // Reemplázalo con tu API Key de PrintNode

export const printReceipt = async ({ printerId, template }) => {
  // const encodedTemplate = btoa(iconv.encode(template, 'CP850'))

  const encodedTemplate = btoa(
    new Uint8Array(iconv.encode(template, 'CP850')).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  )

  try {
    const response = await fetch('https://api.printnode.com/printjobs', {
      method: 'POST',
      headers: {
        Authorization: 'Basic ' + btoa(API_KEY + ':'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        printerId: printerId,
        title: 'Recibo POS',
        contentType: 'raw_base64',
        content: encodedTemplate, // Convierte el texto a Base64
        source: 'POS Web App',
        options: {
          fit_to_page: true,
          // copies: 2,
        },
      }),
    })

    if (!response.ok) throw new Error('Error enviando impresión')

    console.log('Impresión enviada correctamente')
  } catch (error) {
    console.error('Error:', error)
  }
}
