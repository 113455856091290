import { useEffect, useState } from 'react'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { db } from 'config/firebase'
import { formatDate } from 'utils/formatDate'
import usePharmacy from 'hooks/usePharmacy'
import { sumBy } from 'lodash'

export default function useSalesSummary({ date }) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: [],
  })

  const { selectedPharmacy } = usePharmacy()

  const getData = async querySnapshot => {
    try {
      if (querySnapshot?.empty) {
        setState({
          error: false,
          loading: false,
          data: [],
        })
      } else {
        const _data = []

        querySnapshot.forEach(documentSnapshot => {
          const response = documentSnapshot.data()

          const _item = {
            docID: documentSnapshot.id,
            ...response,
          }

          _data.push(_item)
        })

        const totalCount = sumBy(_data, 'count')
        const totalRevenue = sumBy(_data, 'amount')
        const averageTicket = totalRevenue / totalCount

        const result = {
          totalCount,
          totalRevenue,
          averageTicket,
        }

        setState({
          error: false,
          loading: false,
          data: result,
        })
      }
    } catch (error) {
      console.error('Error al obtener el resumen de ventas del dia')
      console.error(error)
    }
  }

  useEffect(() => {
    try {
      const pharmacy_ID = selectedPharmacy?.pharmacy_ID

      if (!pharmacy_ID) return

      const dateString = formatDate(date, 'yyyy-MM-dd')

      const q = query(
        collection(db, 'daily_sales_summary'),
        where('pharmacy_ID', '==', pharmacy_ID),
        where('date', '==', dateString)
      )

      const subscriber = onSnapshot(q, getData, error => {
        console.error(error)
      })

      return () => subscriber()
    } catch (e) {
      console.error(e)
    }
  }, [selectedPharmacy?.pharmacy_ID, date])

  return { state, getData }
}
